import { Button, Pagination } from "antd";
import React, { useState } from "react";
import { FaRegTrashAlt } from "react-icons/fa";
import { IoPaperPlaneOutline } from "react-icons/io5";
import { MdOutlineInsertDriveFile, MdOutlineMailOutline } from "react-icons/md";
import { TiStarOutline } from "react-icons/ti";
import EmailTopHeader from "../../components/emailTopHeader/EmailTopHeader";
import EmailInbox from "../../components/emailInbox/EmailInbox";
import EmailMarked from "../../components/emailMarked/EmailMarked";
import EmailDraft from "../../components/emailDraft/EmailDraft";
import EmailSent from "../../components/emailSent/EmailSent";
import EmailTrash from "../../components/emailTrash/EmailTrash";
import EmailComposeEmail from "../../components/emailcomposeemail/EmailComposeEmail";
import EmailViewAndReply from "../../components/emailviewandreply/EmailViewAndReply";

const EmailTemp = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [composeEmail, setComposeEmail] = useState(false);
  const [viewAndReply, setViewAndReply] = useState(false);

  const onTabsChange = (value) => {
    setComposeEmail(false);
    setViewAndReply(false);
    setActiveTab(value);
  };

  const onNewMessageClick = () => {
    setComposeEmail(true);
    setViewAndReply(false);
  };

  const onViewReplyClick = () => {
    setViewAndReply(true);
    setComposeEmail(false);
  };

  return (
    <div className="email-template-main-wrapper">
      <div className="left-content">
        <Button
          type="primary"
          className="new-message"
          onClick={() => onNewMessageClick()}
        >
          New Message
        </Button>
        <ul className="left-tabs">
          <li
            onClick={() => onTabsChange(1)}
            className={activeTab === 1 ? "active" : ""}
          >
            <div className="inner">
              <MdOutlineMailOutline />
              <p>Inbox</p>
            </div>
            <span className="badge">3</span>
          </li>

          <li
            onClick={() => onTabsChange(2)}
            className={activeTab === 2 ? "active" : ""}
          >
            <div className="inner">
              <TiStarOutline />
              <p>Favorite</p>
            </div>
          </li>

          <li
            onClick={() => onTabsChange(3)}
            className={activeTab === 3 ? "active" : ""}
          >
            <div className="inner">
              <MdOutlineInsertDriveFile />
              <p>Draft</p>
            </div>
          </li>

          <li
            onClick={() => onTabsChange(4)}
            className={activeTab === 4 ? "active" : ""}
          >
            <div className="inner">
              <IoPaperPlaneOutline />
              <p>Sent</p>
            </div>
          </li>

          <li
            onClick={() => onTabsChange(5)}
            className={activeTab === 5 ? "active" : ""}
          >
            <div className="inner">
              <FaRegTrashAlt />
              <p>Trash</p>
            </div>
          </li>
        </ul>
      </div>
      {composeEmail ? (
        <div className="right-content-compose">
          <EmailComposeEmail />
        </div>
      ) : viewAndReply ? (
        <div className="right-content-view-reply">
          <EmailViewAndReply />
        </div>
      ) : (
        <div className="right-content-tabs">
          <div>
            <EmailTopHeader activeTab={activeTab} />

            {activeTab === 1 && (
              <EmailInbox onViewReplyClick={onViewReplyClick} />
            )}
            {activeTab === 2 && <EmailMarked />}
            {activeTab === 3 && <EmailDraft />}
            {activeTab === 4 && <EmailSent />}
            {activeTab === 5 && <EmailTrash />}
          </div>

          <div className="pagination-main">
            <Pagination defaultCurrent={1} total={500} />
          </div>
        </div>
      )}
    </div>
  );
};

export default EmailTemp;
