import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Space, Tooltip } from "antd";
import React from "react";
import { LuMessagesSquare } from "react-icons/lu";
import { TiStarOutline } from "react-icons/ti";
import { BsReplyFill } from "react-icons/bs";
import UseRandomColorStyle from "../userandomcolorstyle/UseRandomColorStyle";

const EmailTempViewAndReply = ({ name }) => {
  const { style } = UseRandomColorStyle();
  const toMeItems = [
    {
      key: "1",
      label: (
        <div className="to_me_dropdown">
          <div className="left">
            <span>From</span>
            <span>Date</span>
            <span>Subject</span>
            <span>Reply-to</span>
          </div>
          <div className="right">
            <span>Emma Bold</span>
            <span>22 Sep 2024, 9:23 </span>
            <span>Trip Reminder. Thank you for flying with us!</span>
            <span>emma@intenso.com</span>
          </div>
        </div>
      ),
    },
  ];

  const getInitials = (name = "") => {
    const words = name.split(" ");

    if (words.length >= 3) {
      return `${words[0].charAt(0).toUpperCase()}${words[words.length - 1]
        .charAt(0)
        .toUpperCase()}`;
    } else {
      return words.map((word) => word.charAt(0).toUpperCase()).join("");
    }
  };

  return (
    <div className="email-temp-main-wrapper">
      <div className="header">
        <div className="left">
          <span className="alpha" style={{ ...style }}>
            {getInitials(name)}
          </span>
          <div className="details-wrapper">
            <div className="details">
              <p>{name}</p>
              <span>1 day ago</span>
            </div>
            <div className="to-me-dropdown">
              <Dropdown
                trigger={"click"}
                overlayClassName="to_me_dropdown_wrapper"
                className="to_me_dropdown_box"
                menu={{
                  items: toMeItems,
                }}
              >
                <a onClick={(e) => e.preventDefault()}>
                  <Space>
                    To me
                    <DownOutlined />
                  </Space>
                </a>
              </Dropdown>
            </div>
          </div>
        </div>
        <div className="right">
          <span className="date">25 Oct 2024, 6:05 pm</span>
          <Tooltip title="Mark as favorite" placement="top">
            <div className="icons">
              <TiStarOutline />
            </div>
          </Tooltip>

          <Tooltip title="Mark as important" placement="top">
            <div className="icons">
              <LuMessagesSquare />
            </div>
          </Tooltip>

          <Tooltip title="Reply" placement="top">
            <div className="icons">
              <BsReplyFill />
            </div>
          </Tooltip>
        </div>
      </div>
      <p>
        Hi Bob,
        <br /> <br /> With resrpect, i must disagree with Mr.Zinsser. We all
        know the most part of important part of any article is the title.Without
        a compelleing title, your reader won't even get to the first
        sentence.After the title, however, the first few sentences of your
        article are certainly the most important part. <br /> <br /> Jornalists
        call this critical, introductory section the "Lede," and when bridge
        properly executed, it's the that carries your reader from an headine try
        at attention-grabbing to the body of your blog post, if you want to get
        it right on of these 10 clever ways to omen your next blog posr with a
        bang. <br /> <br />
        Best regards, <br /> <br />
        Jason Muller
      </p>
    </div>
  );
};

export default EmailTempViewAndReply;
