import React from 'react';
import { Button, Modal, Form, Input, Switch, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { createFeatures } from '../../redux/Features/FeaturesActions';

const AddModal = ({ isModalOpen, setIsModalOpen, packageId, onSuccess }) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const usertoken = useSelector((state) => state.Auth.token);

    const handleCancel = () => {
        form.resetFields();
        setIsModalOpen(false);
    };

    const submitHandler = async (values) => {
        const data = {
            package_id: packageId,
            title: values.name,
            status: values.status ? 1 : 0,
        };

        try {
            await dispatch(createFeatures(data, usertoken));
            message.success('Feature created successfully!');
            form.resetFields();
            setIsModalOpen(false);
            if (onSuccess) onSuccess();
        } catch (error) {
            message.error('Failed to create feature. Please try again.');
        }
    };

    return (
        <Modal
            footer={null}
            title="Create Features"
            open={isModalOpen}
            onCancel={handleCancel}
        >
            <Form
                form={form}
                name="createFeature"
                onFinish={submitHandler}
                autoComplete="off"
                layout="vertical"
                initialValues={{
                    status: true,
                }}
            >
                <Form.Item
                    label="Feature Name"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter the feature name!',
                        },
                    ]}
                >
                    <Input placeholder="Enter feature name" />
                </Form.Item>

                <Form.Item
                    label="Status"
                    name="status"
                    valuePropName="checked"
                >
                    <Switch />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default AddModal;
