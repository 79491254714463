import React, { useState, useEffect } from "react";
import { SocketContext } from "../context/socketContext";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { io } from "socket.io-client";
import HomePage from "../pages/home/homePage";
import Layout from "../components/layout/layouts";
import Brands from "../pages/brands/brands";
// import Companies from "../pages/companies/companies";
import Users from "../pages/users/users";
// import History from "../pages/history/history";
import Visitors from "../pages/visitors/visitors";
import Monitor from "../pages/monitor/monitor";
import Agents from "../pages/agents/agents";
import Shortcuts from "../pages/shortcuts/shortcuts";
import Banned from "../pages/banned/banned";
import Personal from "../pages/personal/personal";
import Account from "../pages/account/account";
// import VisitorsMsg from "../pages/visitors/visitorsmsg";
import AddShortcut from "../pages/shortcuts/addshortcut";
import Triggers from "../pages/triggers/triggers";
import AddTriggers from "../pages/triggers/addtriggers";
import Widget from "../pages/widget";
// import Details from "../pages/history/details";
import OfflineStatus from "../pages/offlineStatus/offlineStatus";
import AddStatus from "../pages/offlineStatus/addStatus";
import Tickets from "../pages/tickets/tickets";
import Currenthistory from "../pages/currenthistory/currenthistory";
import Analytics from "../pages/analytics/analytics";
import EditTriggers from "../pages/triggers/editTriggers";
import Departments from "../pages/departments/departments";
import { BASE_URL_SOCKET } from "../helpers/apicalls/constants/constants";
import CreateTicket from "../pages/tickets/createTicket";
import { showNotification } from "../helpers/fileChanger";
import AllHistory from "../pages/currenthistory/allHistory";
import DragableModal from "../components/dragableModal/dragableModal";


import { incomingMessage } from "../helpers/sounds";
import AgentsRoles from "../pages/agentsroles/AgentsRoles";
import EmailSettings from "../pages/emailSettings";
import EmailTemp from "../pages/emailtemp/EmailTemp";

const MainRoutes = () => {
    const userData = useSelector((state) => state.Auth.userData);
    // const dispatch = useDispatch();
    const [Socket, setSocket] = useState(null);
    const [VisitorData, setVisitorData] = useState([]);
    const [SelectedVisitorData, setSelectedVisitorData] = useState(null);
    const [FiltredUers, setFiltredUsers] = useState([]);
    const [messageId, setmessageId] = useState([]);
    const [connected, setConnected] = useState(false);
    const [TranserStatus, setTranserStatus] = useState([]);
    const [disconnectId, setdisconnectId] = useState("");
    const [allActive, setallActive] = useState([]);
    const [UpdtaedVisitor, setUpdtaedVisitor] = useState(null);
    const [AllActiveVisitors, setAllActiveVisitors] = useState([]);
    const [collapsed, setCollapsed] = useState(false);
    const [settingAllMesage, setSettingAllMesage] = useState(null);
    const [isVisible, setIsVisible] = useState(false);
    const [unSeenChat, setUnSeenChat] = useState([]);
    const [openUserChat, setOpenUserChat] = useState(false);
    const [selectedUserChat, setSelectedUserChat] = useState(null);

    useEffect(() => {
        if (!("Notification" in window)) {
        } else {
            Notification.requestPermission();
        }
    }, []);
    // const REFRESH_TIMEOUT = 10000;
    // let connectTimeout;
    // let refreshTimer;
    useEffect(() => {
        const socket = io(`${BASE_URL_SOCKET}`, {
            autoConnect: true,
            reconnectionDelay: 1000, // defaults to 1000
            reconnectionDelayMax: 1000, // defaults to 5000
            reconnectionAttempts: Infinity,
            auth: {
                token: userData?.assignBrands,
                role: userData?.role,
                visitor: false
            },
        });
        socket.on('connect', () => {
            // clearTimeout(connectTimeout);
            // clearTimeout(refreshTimer);
            // connectTimeout = null;
            // refreshTimer = null;
            // setConnected(true);
        });
        // socket.on('disconnect', () => {
        //     connectTimeout = setTimeout(() => {
        //         setConnected(false);
        //         refreshTimer = setTimeout(() => {
        //             window.location.reload();
        //         }, REFRESH_TIMEOUT);
        //     }, 15000);

        // });

        setSocket(socket);
        return () => {
            socket.disconnect();
        };
    }, []);
    useEffect(() => {
        if (!Socket) return;
        Socket?.on("VisitorArrive", async (data) => {
            setUpdtaedVisitor(data);
        });
        Socket?.on("userLeft", (id) => {
            setdisconnectId(id);
        });
    }, [Socket]);
    //// one_to_one_chat Messsage + Sound
    useEffect(() => {
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        if (!Socket) return;
        const playAudio = () => {
            if (audioContext && !userData?.sounds?.incomingMessages?.isMuted) {
                const audio = new Audio(incomingMessage?.[userData?.sounds?.incomingMessages?.key]?.sound);
                audio.volume = userData?.sounds?.incomingMessages?.volume / 100;
                audio.play()
                    .catch(error => {
                        console.error('Error playing audio:', error);
                    });
            }
        };
        Socket.on("one_to_one_chat", (data) => {
            setSettingAllMesage(data);
            playAudio();
        });
        return () => {
            audioContext.close();
        };
    }, [Socket]);

    useEffect(() => {
        if (settingAllMesage !== null) {
            const existingVisitorIndex = AllActiveVisitors.find(visitor => visitor?.unique_id == settingAllMesage?.senderID);
            if (existingVisitorIndex !== null && existingVisitorIndex !== undefined) {
                if (existingVisitorIndex?.minimized) {
                    const index = messageId?.findIndex(item => item?.unique_id == settingAllMesage?.senderID);
                    if (index !== -1) {
                        const newData = [...messageId];
                        newData[index] = { ...newData[index], value: newData[index]?.value + 1 };
                        setmessageId(newData);
                        settingAllMesage.status = "delivered";
                        Socket.emit("visitorMsgToServer", settingAllMesage);
                        showNotification(settingAllMesage?.senderID, settingAllMesage?.message);
                    } else {
                        setmessageId(prevData => [...prevData, { unique_id: settingAllMesage?.senderID, value: 1 }]);
                        settingAllMesage.status = "delivered";
                        Socket.emit("visitorMsgToServer", settingAllMesage);
                        showNotification(settingAllMesage?.senderID, settingAllMesage?.message);
                    }
                } else {
                    const existingVisitorIndex = AllActiveVisitors?.findIndex(visitor => visitor?.unique_id == settingAllMesage?.senderID);
                    if (existingVisitorIndex !== -1) {
                        setAllActiveVisitors(visitors => {
                            const updatedVisitors = [...visitors];
                            updatedVisitors[existingVisitorIndex] = {
                                ...updatedVisitors[existingVisitorIndex],
                                ...UpdtaedVisitor,
                                isOpen: updatedVisitors[existingVisitorIndex]?.isOpen,
                                zIndex: updatedVisitors[existingVisitorIndex]?.zIndex,
                                minimized: updatedVisitors[existingVisitorIndex]?.minimized,
                                isBlink: true
                            };
                            return updatedVisitors;
                        });

                    }
                }
            } else {
                let findVisitor = VisitorData?.find((x) => x?.unique_id == settingAllMesage?.senderID);
                if (findVisitor) {
                    settingAllMesage.status = "delivered";
                    Socket.emit("visitorMsgToServer", settingAllMesage);
                    showNotification(findVisitor?.unique_id, settingAllMesage?.message);
                    setSelectedVisitorData(findVisitor);
                }
            }
            setSettingAllMesage(null);

        }
    }, [settingAllMesage]);
    //// first Messsage + Sound
    useEffect(() => {
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        if (!Socket) return;
        const playAudio = () => {
            if (audioContext && !userData?.sounds?.chatRequest?.isMuted) {
                const audio = new Audio(incomingMessage?.[userData?.sounds?.chatRequest?.key]?.sound);
                audio.volume = userData?.sounds?.chatRequest?.volume / 100;
                audio.play()
                    .catch(error => {
                        console.error('Error playing audio:', error);
                    });
            }
        };
        Socket.on("visitorFirstMessage", (data) => {
            setSettingAllMesage(data);
            playAudio();
            showNotification(data?.senderID, data?.message);

        });
        return () => {
            audioContext.close();
        };
    }, [Socket]);

    useEffect(() => {
        if (SelectedVisitorData !== null) {
            const existingVisitorIndex = AllActiveVisitors.findIndex(visitor => visitor?.unique_id === SelectedVisitorData?.unique_id);
            setAllActiveVisitors(visitors => {
                const updatedVisitors = visitors.map((visitor, index) => {
                    // Set zIndex to 0 for all visitors except the one at existingVisitorIndex
                    if (index === existingVisitorIndex) {
                        return {
                            ...visitor,
                            ...SelectedVisitorData,
                            isOpen: visitor?.isOpen,
                            zIndex: AllActiveVisitors?.[existingVisitorIndex]?.zIndex === 1 ? 0 : 1, // Set zIndex to 1 for the found visitor
                            minimized: AllActiveVisitors?.[existingVisitorIndex]?.minimized ? false : true,
                            isBlink: visitor?.isBlink,
                            active: AllActiveVisitors?.[existingVisitorIndex]?.active ? false : true
                        };
                    } else {
                        return {
                            ...visitor,
                            zIndex: 0 // Set zIndex to 0 for all other visitors
                        };
                    }
                });

                // If visitor not found, add the new visitor with default zIndex of 1
                if (existingVisitorIndex === -1) {
                    return [...updatedVisitors, { ...SelectedVisitorData, isOpen: true, zIndex: 1, minimized: false, isBlink: false, active: true }];
                }

                return updatedVisitors;
            });

            setSelectedVisitorData(null);
        }
    }, [SelectedVisitorData]);

    useEffect(() => {
        if (UpdtaedVisitor !== null) {
            const existingVisitorIndex = AllActiveVisitors.findIndex(visitor => visitor.unique_id == UpdtaedVisitor.unique_id);
            if (existingVisitorIndex !== -1) {
                setAllActiveVisitors(visitors => {
                    const updatedVisitors = [...visitors];
                    updatedVisitors[existingVisitorIndex] = {
                        ...updatedVisitors[existingVisitorIndex],
                        ...UpdtaedVisitor,
                        isOpen: updatedVisitors[existingVisitorIndex]?.isOpen,
                        zIndex: updatedVisitors[existingVisitorIndex]?.zIndex,
                        minimized: updatedVisitors[existingVisitorIndex]?.minimized,
                        isBlink: updatedVisitors[existingVisitorIndex]?.isBlink
                    };
                    return updatedVisitors;
                });
            }
            setUpdtaedVisitor(null);
        }
    }, [UpdtaedVisitor]);

    useEffect(() => {
        if (disconnectId !== "") {
            const VisitorAllData = VisitorData?.filter(
                (item) => item?.unique_id != disconnectId
            );
            setVisitorData(VisitorAllData);
            setdisconnectId("");
        }
    }, [disconnectId]);

    useEffect(() => {
        const handleVisibilityChange = () => {
            setIsVisible(document.hidden);
        };
        document.addEventListener("visibilitychange", handleVisibilityChange);
        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange);
        };
    }, []);

    return (
        <SocketContext.Provider
            value={{
                selectedUserChat,
                setSelectedUserChat,
                unSeenChat,
                setUnSeenChat,
                openUserChat,
                setOpenUserChat,
                allActive,
                setallActive,
                AllActiveVisitors,
                setAllActiveVisitors,
                setdisconnectId,
                disconnectId,
                setTranserStatus,
                TranserStatus,
                setFiltredUsers,
                FiltredUers,
                connected,
                setSelectedVisitorData,
                SelectedVisitorData,
                Socket,
                VisitorData,
                setVisitorData
            }}>
            <Layout
                messageId={messageId}
                setmessageId={setmessageId}
                setAllActiveVisitors={setAllActiveVisitors}
                AllActiveVisitors={AllActiveVisitors}
                setCollapsed={setCollapsed}
                collapsed={collapsed}>
                <DragableModal
                    setAllActiveVisitors={setAllActiveVisitors}
                    AllActiveVisitors={AllActiveVisitors} />
                <Routes>
                    <Route index path="/" element={<HomePage />} />
                    <Route path="/brands" element={<Brands />} />
                    <Route path="/users" element={<Users />} />
                    <Route path="/analytics" element={<Analytics />} />
                    <Route path="/visitors" element={<Visitors />} />
                    <Route path="/monitor" element={<Monitor />} />
                    <Route path="/agents" element={<Agents />} />
                    <Route path="/shortcuts" element={<Shortcuts />} />
                    <Route path="/banned" element={<Banned />} />
                    <Route path="/personal" element={<Personal />} />
                    <Route path="/account" element={<Account />} />
                    <Route path="/shortcuts/addshortcut" element={<AddShortcut />} />
                    <Route path="/triggers" element={<Triggers />} />
                    <Route path="/triggers/addtriggers" element={<AddTriggers />} />
                    <Route path="/triggers/edit/:triggerIdForEdit" element={<EditTriggers />} />
                    <Route path="/departments" element={<Departments />} />
                    <Route path="/widget" element={<Widget />} />
                    <Route path="/offlineStatus" element={<OfflineStatus />} />
                    <Route path="/offlineStatus/status" element={<AddStatus />} />
                    <Route path="/tickets" element={<Tickets />} />
                    <Route path="/create-ticket" element={<CreateTicket />} />
                    <Route path="/history" element={<Currenthistory />} />
                    <Route path="/all-history" element={<AllHistory />} />
                    <Route path="/agent-roles" element={<AgentsRoles />} />
                    <Route path="/email-settings" element={<EmailSettings />} />
                    <Route path="/email" element={<EmailTemp />} />
                    <Route path="*" element={<HomePage />} />
                </Routes>
            </Layout>
        </SocketContext.Provider >
    );
}

export default MainRoutes;
