import { message } from "antd";
import { Get, Post } from "../../helpers/apicalls/apicalls";
import * as actionTypes from "./PackagesTypes";

export const createPackage = (data, token, afterSuccess) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.IS_PACKAGE_CREATE_LOADING });
        Post('/agents/user/create-package', data, token)
            .then((res) => {
                if (res?.status) {
                    dispatch({ type: actionTypes.PACKAGE_CREATE_SUCCESS });
                    afterSuccess();
                } else {
                    dispatch({
                        type: actionTypes.PACKAGE_CREATE_FALED,
                    });
                    message.error(res?.message, 10);
                }
            })
            .catch((err) => {
                dispatch({
                    type: actionTypes.PACKAGE_CREATE_FALED,
                });
            })
    }
}

export const createPlan = (data, token, afterSuccess) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.CREATE_PLAN_LOADING });
        Post('/agents/user/create-plan-types', data, token)
            .then((res) => {
                if (res?.status) {
                    dispatch({ type: actionTypes.CREATE_PLAN_SUCCESS });
                    afterSuccess();
                } else {
                    dispatch({
                        type: actionTypes.CREATE_PLAN_FALED,
                    });
                    message.error(res?.message, 10);
                }
            })
            .catch((err) => {
                dispatch({
                    type: actionTypes.CREATE_PLAN_FALED,
                });
            })
    }
}

export const getPackages = (data, token) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.IS_LOADING });
        Post('/agents/user/get-packages', data, token)
            .then((res) => {
                if (res?.status) {
                    dispatch({
                        type: actionTypes.PACKAGE_DATA_SUCCESS,
                        payload: res?.data
                    });
                } else {
                    dispatch({ type: actionTypes.PACKAGE_DATA_FALED });
                }
            })
            .catch((err) => {
                dispatch({ type: actionTypes.PACKAGE_DATA_FALED });
            })
    }
}

export const getPlans = (token) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.PLAN_LOADING });
        Get('/agents/user/get-plan-types', token)
            .then((res) => {
                if (res?.status) {
                    dispatch({
                        type: actionTypes.PLAN_SUCCESS,
                        payload: res?.data
                    });
                } else {
                    dispatch({ type: actionTypes.PLAN_FALED });
                }
            })
            .catch((err) => {
                dispatch({ type: actionTypes.PLAN_FALED });
            })
    }
}
export const getPackageById = (values, token) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.IS_PACKAGE_BY_ID_LOADING });
        Post('/agents/user/get-package-by-id', values, token)
            .then((res) => {
                if (res?.status) {
                    dispatch({
                        type: actionTypes.PACKAGE_BY_ID_SUCCESS,
                        payload: res?.data
                    });
                } else {
                    dispatch({ type: actionTypes.PACKAGE_BY_ID_FALED });

                }
            })
            .catch((err) => {
                dispatch({ type: actionTypes.PACKAGE_BY_ID_FALED });

            })
    }
}

export const updatePackage = (data, token, afterSuccess) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.IS_PACKAGE_UPDATE_LOADING });
        Post('/agents/user/update-package', data, token)
            .then((res) => {
                if (res?.status) {
                    dispatch({ type: actionTypes.PACKAGE_UPDATE_SUCCESS });
                    afterSuccess();
                } else {
                    dispatch({
                        type: actionTypes.PACKAGE_UPDATE_FALED,
                    });
                    message.error(res?.message, 10);
                }
            })
            .catch((err) => {
                dispatch({
                    type: actionTypes.PACKAGE_UPDATE_FALED,
                });
            })
    }
}

export const updatePlan = (data, token, afterSuccess) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.UPDATE_PLAN_LOADING });
        Post('/agents/user/update-plan-types', data, token)
            .then((res) => {
                if (res?.status) {
                    dispatch({ type: actionTypes.UPDATE_PLAN_SUCCESS });
                    afterSuccess();
                } else {
                    dispatch({
                        type: actionTypes.UPDATE_PLAN_FALED,
                    });
                    message.error(res?.message, 10);
                }
            })
            .catch((err) => {
                dispatch({
                    type: actionTypes.UPDATE_PLAN_FALED,
                });
            })
    }
}

// Packages Features APis

export const createSidebar = (data, token, afterSuccess) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.CREATE_SIDEBAR_LOADING });
        Post('/agents/user/create-sidebar-module', data, token)
            .then((res) => {
                if (res?.status) {
                    dispatch({ type: actionTypes.CREATE_SIDEBAR_SUCCESS });
                    afterSuccess();
                } else {
                    dispatch({
                        type: actionTypes.CREATE_SIDEBAR_FAILED,
                    });
                    message.error(res?.message, 10);
                }
            })
            .catch((err) => {
                dispatch({
                    type: actionTypes.CREATE_SIDEBAR_FAILED,
                });
            })
    }
}

// get sidebars

export const getSidebar = (token) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.FETCHSIDEBAR_LOADING });
        Get('/agents/user/get-sidebar-module', token)
            .then((res) => {
                if (res?.status) {
                    dispatch({
                        type: actionTypes.FETCHSIDEBAR_SUCCESS,
                        payload: res?.data
                    });
                } else {
                    dispatch({ type: actionTypes.FETCHSIDEBAR_FAILED });
                }
            })
            .catch((err) => {
                dispatch({ type: actionTypes.FETCHSIDEBAR_FAILED });
            })
    }
}

// get sidebars

export const createChildSidebar = (token, data) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.CHILD_SIDEBAR_LOADING });

        Post('/agents/user/create-sidebar-child-module', data, token)
            .then((res) => {
                if (res?.status) {
                    dispatch({
                        type: actionTypes.CHILD_SIDEBAR_SUCCESS,
                        payload: res?.data,
                    });
                    return { success: true, data: res.data };

                } else {
                    const message = res?.message || "Failed to create child sidebar.";
                    if (message === "User is Blocked Please contact to Administrator") {
                        return { success: false, blocked: true, message };
                    }
                    dispatch({
                        type: actionTypes.CHILD_SIDEBAR_FAILED,
                        payload: message,
                    });
                    return { success: false, message };
                }
            })
            .catch((err) => {
                const errorMessage = err?.response?.data?.message || "An unexpected error occurred.";
                dispatch({ type: actionTypes.CHILD_SIDEBAR_FAILED, payload: errorMessage });
                return { success: false, message: errorMessage };
            });
    };
};


//fetch sidebar child
export const fetchChildSidebars = (token, data) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.FETCH_CHILD_SIDEBAR_LOADING });
        Post('/agents/user/get-sidebar-child-module', token, data)
            .then((res) => {
                if (res?.status) {
                    dispatch({
                        type: actionTypes.FETCH_CHILD_SIDEBAR_SUCCESS,
                        payload: res
                    });
                } else {
                    dispatch({ type: actionTypes.FETCH_CHILD_SIDEBAR_FAILED });
                }
            })
            .catch((err) => {
                dispatch({ type: actionTypes.FETCH_CHILD_SIDEBAR_FAILED });
            })
    }
}
//delete Child 


export const deleteChildSidebars = (data) => {
    return async (dispatch) => {
        dispatch({ type: actionTypes.DELETE_CHILD_SIDEBAR_LOADING });
        try {
            const response = await Post('/agents/user/delete-sidebar-child-module', data);

            if (response?.status) {
                dispatch({
                    type: actionTypes.DELETE_CHILD_SIDEBAR_SUCCESS,
                    payload: response?.data,
                });
            } else {
                dispatch({ type: actionTypes.DELETE_CHILD_SIDEBAR_FAILED });
            }
        } catch (err) {
            dispatch({ type: actionTypes.DELETE_CHILD_SIDEBAR_FAILED });
        }
    };
};

//Update Paremt sidebar 
export const updateParentSidebar = (data) => {
    return async (dispatch) => {
        dispatch({ type: actionTypes.UPDATE_SIDEBAR_LOADING });
        try {
            const response = await Post('/agents/user/update-sidebar-module-status', data);
            if (response?.status) {
                dispatch({
                    type: actionTypes.UPDATE_SIDEBAR_SUCCESS,
                    payload: response?.data,
                });
            } else {
                dispatch({ type: actionTypes.UPDATE_SIDEBAR_FAILED });
            }
        } catch (err) {
            dispatch({ type: actionTypes.UPDATE_SIDEBAR_FAILED });
        }
    };
};
