import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, Button, Spin, notification } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { fetchChildSidebars, createChildSidebar, deleteChildSidebars } from '../../redux/Packages/PackagesActions';

export default function ChildSidebarModal({ visible, onClose, parentTitle, sidebarId }) {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const fetchchildSidebarLoading = useSelector((state) => state.PackagesReducer.fetchchildSidebarLoading);
    const fetchchildSidebar = useSelector((state) => state.PackagesReducer.fetchchildSidebar?.data);
    const usertoken = useSelector((state) => state.Auth.token);
    const [childItems, setChildItems] = useState([]);

    useEffect(() => {
        if (!visible) {
            form.resetFields();
            setChildItems([]);
        }
    }, [visible, form]);

    useEffect(() => {
        if (visible && sidebarId) {
            const data = { sidebar_module_id: sidebarId };
            dispatch(fetchChildSidebars(data));
        }
    }, [visible, sidebarId, dispatch]);

    useEffect(() => {
        if (fetchchildSidebar && fetchchildSidebar.length > 0) {
            const initialValues = fetchchildSidebar?.map((item) => item.child_title);
            form.setFieldsValue({ childItems: initialValues });
            setChildItems(fetchchildSidebar);
        } else {
            form.resetFields();
        }
    }, [fetchchildSidebar, form]);

    const handleFormSubmit = async (values) => {
        const newChildItems = values.childItems.filter(
            (item) => !childItems.some((existing) => existing.child_title === item)
        );

        if (newChildItems.length > 0) {
            const data = {
                sidebar_module_id: sidebarId,
                child_title: newChildItems,
            };

            try {
                await dispatch(createChildSidebar(usertoken, data));
                const updatedChildItems = [
                    ...childItems,
                    ...newChildItems.map((title) => ({ child_title: title })),
                ];
                setChildItems(updatedChildItems);
                notification.success({
                    message: 'Success',
                    description: 'Child sidebars have been successfully created.',
                    placement: 'topRight',
                    duration: 3,
                });
                form.resetFields();
                onClose();
            } catch (error) {
                console.error('Error creating child sidebar:', error);
                notification.error({
                    message: 'Error',
                    description: 'Failed to create child sidebars. Please try again.',
                    placement: 'topRight',
                });
            }
        } else {
            notification.warning({
                message: 'No Changes',
                description: 'No new child sidebars were added.',
                placement: 'topRight',
                duration: 3,
            });
        }
    };

    const handleDelete = async (childId) => {
        if (childId) {
            const data = { child_id: childId };
            setChildItems((prevChildItems) => {
                return prevChildItems.filter((item) => item._id !== childId);
            });
            try {
                await dispatch(deleteChildSidebars(data));
                notification.success({
                    message: 'Success',
                    description: 'Child sidebar deleted successfully.',
                    placement: 'topRight',
                    duration: 3,
                });
                onClose(); // Close the modal
            } catch (error) {
                setChildItems((prevChildItems) => {
                    return [...prevChildItems, { _id: childId }];
                });
                notification.error({
                    message: 'Error',
                    description: 'Failed to delete child sidebar. Please try again.',
                    placement: 'topRight',
                });
            }
        }
    };

    return (
        <Modal
            title={`Edit / Add Child Sidebar for ${parentTitle}`}
            open={visible}
            onCancel={onClose}
            footer={null}
        >
            {fetchchildSidebarLoading ? (
                <Spin size="large" />
            ) : (
                <Form
                    form={form}
                    name="dynamic_form_item"
                    style={{ maxWidth: 600 }}
                    onFinish={handleFormSubmit}
                >
                    <Form.List name="childItems">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map((field, index) => (
                                    <Form.Item key={field.key} required={false}>
                                        <Form.Item {...field} noStyle>
                                            <Input
                                                placeholder="Sidebar Child"
                                                style={{ width: '60%' }}
                                            />
                                        </Form.Item>
                                        {fields.length > 1 && (
                                            <MinusCircleOutlined
                                                className="dynamic-delete-button"
                                                style={{ marginLeft: '10px' }}
                                                onClick={() => handleDelete(fetchchildSidebar[index]._id)}
                                            />
                                        )}
                                    </Form.Item>
                                ))}
                                <Form.Item>
                                    <Button
                                        type="dashed"
                                        onClick={() => add()}
                                        style={{ width: '60%' }}
                                        icon={<PlusOutlined />}
                                    >
                                        Add Sidebar Child
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            )}
        </Modal>
    );
}
