import * as actionTypes from "./PackagesTypes";

const INTIAL_STATE = {
    packages: [],
    packagesLoading: false,
    isLoading: false,
    packageById: [],
    plans: [],
    planLoading: false,
    plansUpdate: [],
    plansUpdateLoading: false,
    plansCreate: [],
    plansCreateLoading: false,
    sidebarCreate: [],
    sidebarCreateLoading: false,
    sidebarFetch: [],
    sidebarFetchLoading: false,
    childSidebar: { data: [] },
    childSidebarLoading: false,
    fetchchildSidebar: [],
    fetchchildSidebarLoading: false,
    deletechildSidebar: [],
    deletechildSidebarLoading: false,
    updateSidebar: [],
    updateSidebarLoading: false,
    updatePlan: [],
};

const PackagesReducer = (state = INTIAL_STATE, action) => {
    const { payload } = action;

    switch (action.type) {
        // Get All Packages
        case actionTypes.IS_LOADING:
            return {
                ...state,
                packagesLoading: true
            };

        case actionTypes.PACKAGE_DATA_SUCCESS:
            return {
                ...state,
                packages: payload,
                packagesLoading: false
            };

        case actionTypes.PACKAGE_DATA_FALED:
            return {
                ...state,
                packagesLoading: false
            };

        // Create Package
        case actionTypes.IS_PACKAGE_CREATE_LOADING:
            return {
                ...state,
                isLoading: true
            };

        case actionTypes.PACKAGE_CREATE_SUCCESS:
            return {
                ...state,
                isLoading: false
            };

        case actionTypes.PACKAGE_CREATE_FALED:
            return {
                ...state,
                isLoading: false
            };

        // Package By Id
        case actionTypes.IS_PACKAGE_BY_ID_LOADING:
            return {
                ...state,
                isLoading: true
            };

        case actionTypes.PACKAGE_BY_ID_SUCCESS:
            return {
                ...state,
                packageById: payload,
                isLoading: false
            };

        case actionTypes.PACKAGE_BY_ID_FALED:
            return {
                ...state,
                isLoading: false
            };

        // Update Package
        case actionTypes.IS_PACKAGE_UPDATE_LOADING:
            return {
                ...state,
                isLoading: true
            };

        case actionTypes.PACKAGE_UPDATE_SUCCESS:
            return {
                ...state,
                updatePlan: action.payload || state.updatePlan, // Fallback to current state if no payload
                isLoading: false
            };

        case actionTypes.PACKAGE_UPDATE_FALED:
            return {
                ...state,
                isLoading: false
            };

        // plan
        case actionTypes.PLAN_LOADING:
            return {
                ...state,
                planLoading: true
            };

        case actionTypes.PLAN_SUCCESS:
            return {
                ...state,
                plans: payload,
                planLoading: false,

            };

        case actionTypes.PLAN_FALED:
            return {
                ...state,
                planLoading: false
            };

        // plan update
        case actionTypes.UPDATE_PLAN_LOADING:
            return {
                ...state,
                plansUpdateLoading: true
            };

        case actionTypes.UPDATE_PLAN_SUCCESS:
            return {
                ...state,
                plansUpdate: payload,
                plansUpdateLoading: false,

            };

        case actionTypes.UPDATE_PLAN_FALED:
            return {
                ...state,
                plansUpdateLoading: false
            };


        // plan create
        case actionTypes.CREATE_PLAN_LOADING:
            return {
                ...state,
                plansCreateLoading: true
            };

        case actionTypes.CREATE_PLAN_SUCCESS:
            return {
                ...state,
                plansCreate: payload,
                plansCreateLoading: false,

            };

        case actionTypes.CREATE_PLAN_FALED:
            return {
                ...state,
                plansCreateLoading: false
            };

        // sidebar create
        case actionTypes.CREATE_SIDEBAR_LOADING:
            return {
                ...state,
                sidebarCreateLoading: true
            };

        case actionTypes.CREATE_SIDEBAR_SUCCESS:
            return {
                ...state,
                sidebarCreate: payload,
                sidebarCreateLoading: false,

            };

        case actionTypes.CREATE_SIDEBAR_FAILED:
            return {
                ...state,
                sidebarCreateLoading: false
            };

        // fetch sidebar 
        case actionTypes.FETCHSIDEBAR_LOADING:
            return {
                ...state,
                sidebarFetchLoading: true
            };

        case actionTypes.FETCHSIDEBAR_SUCCESS:
            return {
                ...state,
                sidebarFetch: payload,
                sidebarFetchLoading: false,

            };

        case actionTypes.FETCHSIDEBAR_FAILED:
            return {
                ...state,
                sidebarFetchLoading: false
            };

        // child sidebar 
        case actionTypes.CHILD_SIDEBAR_LOADING:
            return {
                ...state,
                childSidebarLoading: true
            };

        case actionTypes.CHILD_SIDEBAR_SUCCESS:
            return {
                ...state,
                childSidebar: payload,
                childSidebarLoading: false,

            };

        case actionTypes.CHILD_SIDEBAR_FAILED:
            return {
                ...state,
                childSidebarLoading: false
            };

        // fetch child sidebar 
        case actionTypes.FETCH_CHILD_SIDEBAR_LOADING:
            return {
                ...state,
                fetchchildSidebarLoading: true
            };

        case actionTypes.FETCH_CHILD_SIDEBAR_SUCCESS:
            return {
                ...state,
                fetchchildSidebar: payload,

                fetchchildSidebarLoading: false,

            };

        case actionTypes.FETCH_CHILD_SIDEBAR_FAILED:
            return {
                ...state,
                fetchchildSidebarLoading: false
            };

        // delete child sidebar 
        case actionTypes.DELETE_CHILD_SIDEBAR_LOADING:
            return {
                ...state,
                deletechildSidebarLoading: true
            };

        case actionTypes.DELETE_CHILD_SIDEBAR_SUCCESS:
            return {
                ...state,
                deletechildSidebar: payload,
                deletechildSidebarLoading: false,
            };

        case actionTypes.DELETE_CHILD_SIDEBAR_FAILED:
            return {
                ...state,
                deletechildSidebarLoading: false
            };

        // update parent 
        case actionTypes.UPDATE_SIDEBAR_LOADING:
            return {
                ...state,
                updateSidebarLoading: true
            };

        case actionTypes.UPDATE_SIDEBAR_SUCCESS:
            return {
                ...state,
                updateSidebar: payload,
                updateSidebarLoading: false,
            };

        case actionTypes.UPDATE_SIDEBAR_FAILED:
            return {
                ...state,
                updateSidebarLoading: false
            };
        default:
            return state;
    }
}

export default PackagesReducer;