import React, { useState, useRef } from "react";
import "./AudioPlayerCustom.scss";
import { FaPlay } from "react-icons/fa6";
import { FaPause } from "react-icons/fa6";

let currentlyPlayingAudio = null;

const AudioPlayerCustom = ({ audioUrl }) => {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState("0:00");
  const [progress, setProgress] = useState(0);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60)
      .toString()
      .padStart(2, "0");
    return `${minutes}:${seconds}`;
  };
  const handlePlayPause = () => {
    const audio = audioRef.current;

    if (audio.paused) {
      if (currentlyPlayingAudio && currentlyPlayingAudio !== audio) {
        currentlyPlayingAudio.pause();
        currentlyPlayingAudio.dispatchEvent(new Event("pause"));
      }
      currentlyPlayingAudio = audio;
      audio
        .play()
        .then(() => setIsPlaying(true))
        .catch((error) => console.error("Error playing audio:", error));
    } else {
      audio.pause();
      setIsPlaying(false);
    }
  };

  const handleTimeUpdate = () => {
    const audio = audioRef.current;
    const progress = (audio.currentTime / audio.duration) * 100;
    setProgress(progress);
    setCurrentTime(formatTime(audio.currentTime));
  };

  const handleProgressBarChange = (e) => {
    const audio = audioRef.current;
    const newTime = (e.target.value / 100) * audio.duration;
    audio.currentTime = newTime;
    setProgress(e.target.value); 
  };

  const handleAudioEnded = () => {
    setIsPlaying(false);
    setProgress(0);
    setCurrentTime("0:00");
  };

  return (
    <div
      className="audio-player"
      style={{ display: "flex", alignItems: "center", width: "100%" }}
    >
      <button className="play-pause-btn" onClick={handlePlayPause}>
        {isPlaying ? <FaPause /> : <FaPlay />}
      </button>
      <input
        type="range"
        className="progress-bar"
        value={progress}
        onChange={handleProgressBarChange}
        style={{ flex: 1, margin: "0px 5px 0px 8px" }}
      />
      <span className="time-display">{currentTime}</span>
      <audio
        className="audio-element"
        src={audioUrl}
        ref={audioRef}
        onTimeUpdate={handleTimeUpdate}
        onEnded={handleAudioEnded}
      />
    </div>
  );
};

export default AudioPlayerCustom;
