import React from "react";
import EmailInboxMessages from "../emailinboxmessages/EmailInboxMessages";

const EmailInbox = ({ onViewReplyClick }) => {
  return (
    <div className="overflow-div">
      <EmailInboxMessages
        name={"Melody Macy"}
        message={"Digital PPV Customer Confirmation"}
        time={"8:30 PM"}
        isRead={false}
        onViewReplyClick={onViewReplyClick}
      />
      <EmailInboxMessages
        name={"Max Smith"}
        message={"Your iBuy.com grocery shopping confirmation"}
        time={"day ago"}
        isRead={false}
        onViewReplyClick={onViewReplyClick}
      />
      <EmailInboxMessages
        name={"Sean Bean"}
        message={"Your Order #224820998666029 has been Confirmed"}
        time={"11:20 PM"}
        isRead={true}
        onViewReplyClick={onViewReplyClick}
      />
      <EmailInboxMessages
        name={"Brian Cox"}
        message={"Payment Notification DLOP2329KD"}
        time={"2 days ago"}
        isRead={true}
        onViewReplyClick={onViewReplyClick}
      />
      <EmailInboxMessages
        name={"Mikaela Collins"}
        message={"Congratulations on your iRun Coach subscription"}
        time={"July 25"}
        isRead={true}
        onViewReplyClick={onViewReplyClick}
      />
      <EmailInboxMessages
        name={"Francis Mitcham"}
        message={"Pay bills & win up to 600$ Cashback!"}
        time={"July 24"}
        isRead={true}
        onViewReplyClick={onViewReplyClick}
      />
      <EmailInboxMessages
        name={"Olivia Wild"}
        message={"Activate your LIPO Account today"}
        time={"July 13"}
        isRead={true}
        onViewReplyClick={onViewReplyClick}
      />
      <EmailInboxMessages
        name={"Neil Owen"}
        message={"About your request for PalmLake"}
        time={"May 25"}
        isRead={false}
        onViewReplyClick={onViewReplyClick}
      />
      <EmailInboxMessages
        name={"Dan Wilson"}
        message={"Welcome, Patty"}
        time={"April 15"}
        isRead={true}
        onViewReplyClick={onViewReplyClick}
      />
      <EmailInboxMessages
        name={"Emma Bold"}
        message={"Free Video Marketing Guide"}
        time={"April 3"}
        isRead={false}
        onViewReplyClick={onViewReplyClick}
      />
      <EmailInboxMessages
        name={"Ana Crown"}
        message={"Your iBuy.com grocery shopping confirmation"}
        time={"March 17"}
        isRead={true}
        onViewReplyClick={onViewReplyClick}
      />
      <EmailInboxMessages
        name={"Robert Doe"}
        message={"Your Order #224820998666029 has been Confirmed"}
        time={"March 12"}
        isRead={true}
        onViewReplyClick={onViewReplyClick}
      />
      <EmailInboxMessages
        name={"John Miller"}
        message={"Payment Notification DLOP2329KD"}
        time={"March 11"}
        isRead={true}
        onViewReplyClick={onViewReplyClick}
      />
    </div>
  );
};

export default EmailInbox;
