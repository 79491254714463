import React, { useState, useEffect } from "react";
import { Col, Row, Breadcrumb, message } from "antd";
import { Button, Form, Input, Select, Checkbox } from "antd";
import TextArea from "antd/es/input/TextArea";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { getTriggerActions, getTriggerById, getTriggerConditions, updateTrigger } from "../../redux/Triggers/TriggersActions";
import { useNavigate, useParams } from "react-router-dom";

const EditTriggers = () => {

    const authData = useSelector((state) => state.Auth.userData);
    const usertoken = useSelector((state) => state.Auth.token);
    const conditionsData = useSelector((state) => state.Triggers.conditions);
    const actionsData = useSelector((state) => state.Triggers.actions);
    const triggerModel = useSelector((state) => state.Triggers.editTrigger);
    const brands = useSelector((state) => state.Auth.userData.assignBrands);
    const dispatch = useDispatch();
    const [triggerName, setTriggerName] = useState('');
    const [triggerMessage, setTriggerMessage] = useState('');
    const [fireOnce, setFireOnce] = useState(false);
    const [status, setStatus] = useState(false);
    const [conditionsField1, setConditionsField1] = useState([{ "type": "", "options": [], "description": "" }]);
    const [conditionsField2, setConditionsField2] = useState([{ "type": "", "options": [], "description": "" }]);
    const [actionField1, setActionField1] = useState([{ "type": "", "options": [], "placeholder": "" }]);
    const [actionField2, setActionField2] = useState([{ "type": "", "options": [], "placeholder": "" }]);
    const [conditions, setConditions] = useState([{}]);
    const [actions, setActions] = useState([{}]);
    const [size, setSize] = useState("1");
    const [runTrigger, setRunTrigger] = useState("page_enter");
    const [checkCondition, setCheckCondition] = useState("and");
    const [triggerBrand, setTriggerBrand] = useState('');
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const { triggerIdForEdit } = useParams();

    useEffect(() => {
        dispatch(getTriggerById(triggerIdForEdit, usertoken));
    }, [triggerIdForEdit != undefined]);

    useEffect(() => {
        setTriggerName(triggerModel.triggerName);
        setTriggerMessage(triggerModel.triggerMessage);
        setFireOnce(triggerModel.onlyOnce);
        setTriggerBrand(triggerModel.brandId);
        setStatus(triggerModel.status);
        setRunTrigger(triggerModel.runTrigger);
        setCheckCondition(triggerModel.checkCondition);
        const consData = []
        const conditionsFieldData1 = []
        const conditionsFieldData2 = []
        for (var i = 0; i < triggerModel?.conditions?.length; i++) {
            const data = {
                conditionVal1: triggerModel.conditions[i].conditionVal1,
                conditionVal2: triggerModel.conditions[i].conditionVal2,
                conditionVal3: triggerModel.conditions[i].conditionVal3
            }
            for (var j = 0; j < conditionsData.length; j++) {
                for (var k = 0; k < conditionsData[j]?.conditions?.length; k++) {
                    if (conditionsData[j].conditions[k].name === triggerModel.conditions[i].conditionVal1) {
                        const dataField1 = {
                            "type": conditionsData[j].conditions[k].name == triggerModel.conditions[i].conditionVal1 ? conditionsData[j].conditions[k].field1?.type : "",
                            "options": conditionsData[j].conditions[k].name == triggerModel.conditions[i].conditionVal1 ? conditionsData[j].conditions[k].field1?.options : [],
                            "description": conditionsData[j].conditions[k].name == triggerModel.conditions[i].conditionVal1 ? conditionsData[j].conditions[k].description : ""
                        }
                        conditionsFieldData1.push(dataField1);
                        if (conditionsData[j].conditions[k]?.field2?.type !== null && conditionsData[j].conditions[k]?.field2?.type !== "" && conditionsData[j].conditions[k]?.field2?.type !== undefined) {
                            const dataField2 = {
                                "type": conditionsData[j].conditions[k].name == triggerModel.conditions[i].conditionVal1 ? conditionsData[j].conditions[k].field2?.type : "",
                                "options": conditionsData[j].conditions[k].name == triggerModel.conditions[i].conditionVal1 ? conditionsData[j].conditions[k].field2?.options : [],
                                "description": conditionsData[j].conditions[k].name == triggerModel.conditions[i].conditionVal1 ? conditionsData[j].conditions[k].description : ""
                            }
                            conditionsFieldData2.push(dataField2);
                        } else {
                            const dataField2 = {}
                            conditionsFieldData2.push(dataField2);
                        }
                    }
                }
            }
            consData.push(data);
        }

        setConditions(consData);
        setConditionsField1(conditionsFieldData1);
        setConditionsField2(conditionsFieldData2);

        const actnsData = []
        const actionsFieldData1 = []
        const actionsFieldData2 = []
        for (var i = 0; i < triggerModel?.actions?.length; i++) {
            const data = {
                actionVal1: triggerModel.actions[i].actionVal1,
                actionVal2: triggerModel.actions[i].actionVal2,
                actionVal3: triggerModel.actions[i].actionVal3
            }
            for (var j = 0; j < actionsData.length; j++) {
                if (actionsData[j]?.name === triggerModel?.actions[i]?.actionVal1) {
                    const dataField1 = {
                        "type": actionsData[j]?.name === triggerModel?.actions[i]?.actionVal1 ? actionsData[j]?.field1.type : "",
                        "options": actionsData[j]?.name === triggerModel?.actions[i].actionVal1 ? actionsData[j]?.field1.options : [],
                        "placeholder": actionsData[j]?.name === triggerModel?.actions[i].actionVal1 ? actionsData[j]?.field1.placeholder : "",
                        "description": actionsData[j]?.name === triggerModel?.actions[i].actionVal1 ? actionsData[j]?.description : ""
                    }
                    actionsFieldData1.push(dataField1);
                    if (actionsData[j].field2 !== null && actionsData[j].field2 !== "" && actionsData[j].field2 !== undefined) {
                        const dataField2 = {
                            "type": actionsData[j]?.name === triggerModel?.actions[i].actionVal1 ? actionsData[j]?.field2.type : "",
                            "options": actionsData[j]?.name === triggerModel?.actions[i].actionVal1 ? actionsData[j]?.field2.options : [],
                            "placeholder": actionsData[j]?.name === triggerModel?.actions[i].actionVal1 ? actionsData[j]?.field2.placeholder : "",
                            "description": actionsData[j]?.name === triggerModel?.actions[i].actionVal1 ? actionsData[j]?.description : ""
                        }
                        actionsFieldData2.push(dataField2);
                    } else {
                        const dataField2 = {}
                        actionsFieldData2.push(dataField2);
                    }
                }
            }
            actnsData.push(data);
        }

        setActions(actnsData);
        setActionField1(actionsFieldData1);
        setActionField2(actionsFieldData2);

    }, [triggerModel, conditionsData, actionsData]);

    useEffect(() => {
        getConditions();
        getActions();
    }, []);

    const { Option, OptGroup } = Select;

    const options = [];

    for (let i = 10; i < 36; i++) {
        options.push({
            value: i.toString(36) + i,
            label: i.toString(36) + i,
        });
    }

    const getConditions = () => {
        dispatch(getTriggerConditions(usertoken));
    }

    const getActions = () => {
        dispatch(getTriggerActions(usertoken));
    }

    const addConditionsFieldHandler = () => {
        setConditions([...conditions, {}]);
        setConditionsField1([...conditionsField1, { "type": "", "options": [], "description": "" }]);
        setConditionsField2([...conditionsField2, { "type": "", "options": [], "description": "" }]);
    };

    const addActionsFieldHandler = () => {
        setActions([...actions, {}]);
        setActionField1([...actionField1, { "type": "", "options": [], "placeholder": "" }]);
        setActionField2([...actionField2, { "type": "", "options": [], "placeholder": "" }]);
    };

    const deleteConditionsFieldHandler = (i) => {
        const deleteVal = [...conditions];
        const val1 = [...conditionsField1];
        const val2 = [...conditionsField2];
        deleteVal.splice(i, 1);
        val1.splice(i, 1);
        val2.splice(i, 1);

        setConditions(deleteVal);
        setConditionsField1(val1);
        setConditionsField2(val2);
    };

    const deleteActionsFieldHandler = (i) => {
        const deleteVal = [...actions];
        const val1 = [...actionField1];
        const val2 = [...actionField2];
        deleteVal.splice(i, 1);
        val1.splice(i, 1);
        val2.splice(i, 1);
        setActions(deleteVal);
        setActionField1(val1);
        setActionField2(val2);
    };

    const enableHandler = (e) => {
        const name = e.target.name;
        const checked = e.target.checked;
        if (name == "fireOnce") {
            if (checked) {
                setFireOnce(true);
            } else {
                setFireOnce(false);
            }
        } else if (name == "status") {
            if (checked) {
                setStatus(true);
            } else {
                setStatus(false);
            }
        }
    };

    const onChangeHandler = (e) => {
        const name = e.target.name;
        const validationErrors = errors;
        if (name == 'name') {
            setTriggerName(e.target.value);
            delete validationErrors.triggerName;
        } else if (name == 'message') {
            setTriggerMessage(e.target.value);
            delete validationErrors.triggerMessage;
        }
    }

    const onBrandSelectHandler = (value) => {
        setTriggerBrand(value);
        const validationErrors = errors;
        delete validationErrors.triggerBrand;
    }

    const onRunTriggerSelectHandler = (value) => {
        setRunTrigger(value);
    }

    const onCheckConditionSelectHandler = (value) => {
        setCheckCondition(value);
    }

    const onSelectConditionHandler = (val) => {
        var mainIndex = val.split('|')[1];
        const validationErrors = errors;

        var selectedData = conditionsData.filter(i => i.conditions.find(j => j.name === val.split('|')[0]));

        var newField1 = {
            "type": selectedData[0].conditions.find(j => j.name === val.split('|')[0]).field1 !== null ? selectedData[0].conditions.find(j => j.name === val.split('|')[0]).field1?.type : null,
            "options": selectedData[0].conditions.find(j => j.name === val.split('|')[0]).field1 !== null ? selectedData[0].conditions.find(j => j.name === val.split('|')[0]).field1?.options : null,
            "description": selectedData[0].conditions.find(j => j.name === val.split('|')[0]).field1 !== null ? selectedData[0].conditions.find(j => j.name === val.split('|')[0]).description : null
        };

        var newField2 = {
            "type": selectedData[0].conditions.find(j => j.name === val.split('|')[0]).field2 !== null ? selectedData[0].conditions.find(j => j.name === val.split('|')[0]).field2?.type : null,
            "options": selectedData[0].conditions.find(j => j.name === val.split('|')[0]).field2 !== null ? selectedData[0].conditions.find(j => j.name === val.split('|')[0]).field2?.options : null,
            "description": selectedData[0].conditions.find(j => j.name === val.split('|')[0]).field2 !== null ? selectedData[0].conditions.find(j => j.name === val.split('|')[0]).description : null
        };

        if (conditionsField1[mainIndex]) {
            conditionsField1[mainIndex] = newField1;
            setConditionsField1([...conditionsField1]);
        } else {
            setConditionsField1([newField1]);
        }

        if (conditionsField2[mainIndex]) {
            conditionsField2[mainIndex] = newField2;
            setConditionsField2([...conditionsField2]);
        } else {
            setConditionsField2([newField2]);
        }

        if (selectedData[0].conditions.find(j => j.name === val.split('|')[0]).field2 !== null) {
            conditions[mainIndex] = {
                'conditionVal1': selectedData[0].conditions.find(j => j.name === val.split('|')[0]).name,
                'conditionVal2': selectedData[0].conditions.find(j => j.name === val.split('|')[0]).field1?.options ? selectedData[0].conditions.find(j => j.name === val.split('|')[0]).field1?.options[0].value : '',
                'conditionVal3': ''
            };
        } else {
            conditions[mainIndex] = {
                'conditionVal1': selectedData[0].conditions.find(j => j.name === val.split('|')[0]).name,
                'conditionVal2': selectedData[0].conditions.find(j => j.name === val.split('|')[0]).field1?.options ? selectedData[0].conditions.find(j => j.name === val.split('|')[0]).field1?.options[0].value : '',
            };
        }

        setConditions([...conditions]);
        if (validationErrors.conditions?.length > 0) {
            delete validationErrors.conditions[validationErrors.conditions.findIndex(x => x.index == mainIndex)]?.conditionVal1;
            delete validationErrors.conditions[validationErrors.conditions.findIndex(x => x.index == mainIndex)]?.conditionVal2;
            delete validationErrors.conditions[validationErrors.conditions.findIndex(x => x.index == mainIndex)]?.conditionVal3;
            if (!validationErrors.conditions[validationErrors.conditions.findIndex(x => x.index == mainIndex)]?.conditionVal1 && !validationErrors.conditions[validationErrors.conditions.findIndex(x => x.index == mainIndex)]?.conditionVal2 && !validationErrors.conditions[validationErrors.conditions.findIndex(x => x.index == mainIndex)]?.conditionVal3) {
                delete validationErrors.conditions[validationErrors.conditions.findIndex(x => x.index == mainIndex)]?.index;
            }
        }
    }

    const onSelectActionHandler = (val) => {
        var mainIndex = val.split('|')[1];
        const validationErrors = errors;
        var selectedData = actionsData.find(i => i.name === val.split('|')[0]);

        var newField1 = {
            "type": selectedData.field1?.type,
            "options": selectedData.field1?.options,
            "placeholder": selectedData.field1?.placeholder,
            "description": selectedData.description
        };

        var newField2 = {
            "type": selectedData.field2?.type,
            "options": selectedData.field2?.options,
            "placeholder": selectedData.field2?.placeholder,
            "description": selectedData.description
        };

        if (actionField1[mainIndex]) {
            actionField1[mainIndex] = newField1;
            setActionField1([...actionField1]);
        } else {
            setActionField1([newField1]);
        }

        if (actionField2[mainIndex]) {
            actionField2[mainIndex] = newField2;
            setActionField2([...actionField2]);
        } else {
            setActionField2([newField2]);
        }

        if (selectedData.field2 !== null) {
            actions[mainIndex] = {
                'actionVal1': selectedData.name,
                'actionVal2': selectedData.field1?.options ? selectedData.field1?.options[0].value : '',
                'actionVal3': ''
            };
        } else {
            actions[mainIndex] = {
                'actionVal1': selectedData.name,
                'actionVal2': selectedData.field1?.options ? selectedData.field1?.options[0].value : ''
            };
        }

        setActions([...actions]);
        if (validationErrors.actions?.length > 0) {
            delete validationErrors.actions[validationErrors.actions.findIndex(x => x.index == mainIndex)]?.actionVal1;
            delete validationErrors.actions[validationErrors.actions.findIndex(x => x.index == mainIndex)]?.actionVal2;
            delete validationErrors.actions[validationErrors.actions.findIndex(x => x.index == mainIndex)]?.actionVal3;
            if (!validationErrors.actions[validationErrors.actions.findIndex(x => x.index == mainIndex)]?.actionVal1 && !validationErrors.actions[validationErrors.actions.findIndex(x => x.index == mainIndex)]?.actionVal2 && !validationErrors.actions[validationErrors.actions.findIndex(x => x.index == mainIndex)]?.actionVal3) {
                delete validationErrors.actions[validationErrors.actions.findIndex(x => x.index == mainIndex)].index;
            }
        }
    }

    const onConditionOperatorsSelectChange = (val) => {
        var mainIndex = val.split('|')[1];
        const validationErrors = errors;
        conditions[mainIndex].conditionVal2 = val.split('|')[0];
        setConditions([...conditions]);
        if (validationErrors.conditions?.length > 0) {
            if (validationErrors.conditions[validationErrors.conditions.findIndex(x => x.index == mainIndex)]?.conditionVal2) {
                delete validationErrors.conditions[validationErrors.conditions.findIndex(x => x.index == mainIndex)]?.conditionVal2;
                if (!validationErrors.conditions[validationErrors.conditions.findIndex(x => x.index == mainIndex)]?.conditionVal1 && !validationErrors.conditions[validationErrors.conditions.findIndex(x => x.index == mainIndex)]?.conditionVal2 && !validationErrors.conditions[validationErrors.conditions.findIndex(x => x.index == mainIndex)]?.conditionVal3) {
                    delete validationErrors.conditions[validationErrors.conditions.findIndex(x => x.index == mainIndex)]?.index;
                }
            }
        }
    }

    const onConditionOperatorsChange = (e, index) => {
        const validationErrors = errors;
        conditions[index].conditionVal2 = e.target.value;
        setConditions([...conditions]);
        if (validationErrors.conditions?.length > 0) {
            if (validationErrors.conditions[validationErrors.conditions.findIndex(x => x.index == index)]?.conditionVal2) {
                delete validationErrors.conditions[validationErrors.conditions.findIndex(x => x.index == index)]?.conditionVal2;
                if (!validationErrors.conditions[validationErrors.conditions.findIndex(x => x.index == index)]?.conditionVal1 && !validationErrors.conditions[validationErrors.conditions.findIndex(x => x.index == index)]?.conditionVal2 && !validationErrors.conditions[validationErrors.conditions.findIndex(x => x.index == index)]?.conditionVal3) {
                    delete validationErrors.conditions[validationErrors.conditions.findIndex(x => x.index == index)]?.index;
                }
            }
        }
    }

    const onConditionalFieldSelectChange = (val) => {
        var mainIndex = val.split('|')[1];
        const validationErrors = errors;
        conditions[mainIndex].conditionVal3 = val.split('|')[0];
        setConditions([...conditions]);
        if (validationErrors.conditions?.length > 0) {
            if (validationErrors.conditions[validationErrors.conditions.findIndex(x => x.index == mainIndex)]?.conditionVal3) {
                delete validationErrors.conditions[validationErrors.conditions.findIndex(x => x.index == mainIndex)]?.conditionVal3;
                if (!validationErrors.conditions[validationErrors.conditions.findIndex(x => x.index == mainIndex)]?.conditionVal1 && !validationErrors.conditions[validationErrors.conditions.findIndex(x => x.index == mainIndex)]?.conditionVal2 && !validationErrors.conditions[validationErrors.conditions.findIndex(x => x.index == mainIndex)]?.conditionVal3) {
                    delete validationErrors.conditions[validationErrors.conditions.findIndex(x => x.index == mainIndex)]?.index;
                }
            }
        }
    }

    const onConditionalFieldChange = (e, index) => {
        const validationErrors = errors;
        conditions[index].conditionVal3 = e.target.value;
        setConditions([...conditions]);
        if (validationErrors.conditions?.length > 0) {
            if (validationErrors.conditions[validationErrors.conditions.findIndex(x => x.index == index)]?.conditionVal3) {
                delete validationErrors.conditions[validationErrors.conditions.findIndex(x => x.index == index)]?.conditionVal3;
                if (!validationErrors.conditions[validationErrors.conditions.findIndex(x => x.index == index)]?.conditionVal1 && !validationErrors.conditions[validationErrors.conditions.findIndex(x => x.index == index)]?.conditionVal2 && !validationErrors.conditions[validationErrors.conditions.findIndex(x => x.index == index)]?.conditionVal3) {
                    delete validationErrors.conditions[validationErrors.conditions.findIndex(x => x.index == index)]?.index;
                }
            }
        }
    }

    const onActionFieldOneSelectChange = (val) => {
        var mainIndex = val.split('|')[1];
        const validationErrors = errors;
        actions[mainIndex].actionVal2 = val.split('|')[0];
        setActions([...actions]);
        if (validationErrors.actions?.length > 0) {
            if (validationErrors.actions[validationErrors.actions.findIndex(x => x.index == mainIndex)]?.actionVal2) {
                delete validationErrors.actions[validationErrors.actions.findIndex(x => x.index == mainIndex)]?.actionVal2;
                if (!validationErrors.actions[validationErrors.actions.findIndex(x => x.index == mainIndex)]?.actionVal1 && !validationErrors.actions[validationErrors.actions.findIndex(x => x.index == mainIndex)]?.actionVal2 && !validationErrors.actions[validationErrors.actions.findIndex(x => x.index == mainIndex)]?.actionVal3) {
                    delete validationErrors.actions[validationErrors.actions.findIndex(x => x.index == mainIndex)]?.index;
                }
            }
        }
    }

    const onActionFieldOneChange = (e, index) => {
        const validationErrors = errors;
        actions[index].actionVal2 = e.target.value;
        setActions([...actions]);
        if (validationErrors.actions?.length > 0) {
            if (validationErrors.actions[validationErrors.actions.findIndex(x => x.index == index)]?.actionVal2) {
                delete validationErrors.actions[validationErrors.actions.findIndex(x => x.index == index)]?.actionVal2;
                if (!validationErrors.actions[validationErrors.actions.findIndex(x => x.index == index)]?.actionVal1 && !validationErrors.actions[validationErrors.actions.findIndex(x => x.index == index)]?.actionVal2 && !validationErrors.actions[validationErrors.actions.findIndex(x => x.index == index)]?.actionVal3) {
                    delete validationErrors.actions[validationErrors.actions.findIndex(x => x.index == index)]?.index;
                }
            }
        }
    }

    const onActionFieldTwoSelectChange = (val) => {
        const validationErrors = errors;
        var mainIndex = val.split('|')[1];
        actions[mainIndex].actionVal3 = val.split('|')[0];
        setActions([...actions]);
        if (validationErrors.actions?.length > 0) {
            if (validationErrors.actions[validationErrors.actions.findIndex(x => x.index == mainIndex)]?.actionVal3) {
                delete validationErrors.actions[validationErrors.actions.findIndex(x => x.index == mainIndex)]?.actionVal3;
                if (!validationErrors.actions[validationErrors.actions.findIndex(x => x.index == mainIndex)]?.actionVal1 && !validationErrors.actions[validationErrors.actions.findIndex(x => x.index == mainIndex)]?.actionVal2 && !validationErrors.actions[validationErrors.actions.findIndex(x => x.index == mainIndex)]?.actionVal3) {
                    delete validationErrors.actions[validationErrors.actions.findIndex(x => x.index == mainIndex)]?.index;
                }
            }
        }
    }

    const onActionFieldTwoChange = (e, index) => {
        const validationErrors = errors;
        actions[index].actionVal3 = e.target.value;
        setActions([...actions]);
        if (validationErrors.actions?.length > 0) {
            if (validationErrors.actions[validationErrors.actions.findIndex(x => x.index == index)]?.actionVal3) {
                delete validationErrors.actions[validationErrors.actions.findIndex(x => x.index == index)].actionVal3;
                if (!validationErrors.actions[validationErrors.actions.findIndex(x => x.index == index)]?.actionVal1 && !validationErrors.actions[validationErrors.actions.findIndex(x => x.index == index)]?.actionVal2 && !validationErrors.actions[validationErrors.actions.findIndex(x => x.index == index)]?.actionVal3) {
                    delete validationErrors.actions[validationErrors.actions.findIndex(x => x.index == index)]?.index;
                }
            }
        }
    }

    const onSuccessRedirection = (msg) => {
        if (msg) {
            message.success(`${msg}`);
            navigate('/triggers');
        }
        else {
            message.success("Submit success!");
            navigate('/triggers');
        }
    }

    const onSubmithandler = () => {

        const validate = checkValidation();
        if (validate) {
            const data = {
                triggerId: triggerIdForEdit,
                triggerName: triggerName,
                triggerMessage: triggerMessage,
                companyId: authData.companyId,
                brandId: triggerBrand,
                status: status,
                onlyOnce: true,
                // onlyOnce: fireOnce,
                runTrigger: runTrigger,
                checkCondition: checkCondition,
                conditions: conditions,
                actions: actions
            };

            dispatch(updateTrigger(data, usertoken, onSuccessRedirection));
        }
    }

    const checkValidation = () => {
        const validationErrors = {};

        if (triggerName === null || triggerName === "" || triggerName === undefined) {
            validationErrors.triggerName = "This field is required."
        }

        if (triggerMessage === null || triggerMessage === "" || triggerMessage === undefined) {
            validationErrors.triggerMessage = "This field is required."
        }

        if (triggerBrand === null || triggerBrand === "" || triggerBrand === undefined) {
            validationErrors.triggerBrand = "This field is required."
        }

        if (conditions.length > 0) {
            validationErrors.conditions = [];
            conditions.forEach((condition, index) => {
                const conditionErrors = {};
                if (!condition.conditionVal1) {
                    conditionErrors.conditionVal1 = "This field is required.";
                    conditionErrors.index = index;
                } else {
                    if (!condition.conditionVal2) {
                        conditionErrors.conditionVal2 = "This field is required.";
                        conditionErrors.index = index;
                    }

                    if (condition.conditionVal3 === "") {
                        conditionErrors.conditionVal3 = "This field is required.";
                        conditionErrors.index = index;
                    }
                }

                if (conditionErrors.conditionVal1 || conditionErrors.conditionVal2 || conditionErrors.conditionVal3) {
                    validationErrors.conditions.push(conditionErrors);
                }
            });
        }

        if (actions.length > 0) {
            validationErrors.actions = [];
            actions.forEach((action, index) => {
                const actionErrors = {};
                if (!action.actionVal1) {
                    actionErrors.actionVal1 = "This field is required.";
                    actionErrors.index = index;
                } else {
                    if (!action.actionVal2) {
                        actionErrors.actionVal2 = "This field is required.";
                        actionErrors.index = index;
                    }

                    if (action.actionVal3 === "") {
                        actionErrors.actionVal3 = "This field is required.";
                        actionErrors.index = index;
                    }
                }

                if (actionErrors.actionVal1 || actionErrors.actionVal2 || actionErrors.actionVal3) {
                    validationErrors.actions.push(actionErrors);
                }
            });
        }

        if (validationErrors.conditions.length <= 0) {
            delete validationErrors.conditions;
        }

        if (validationErrors.actions.length <= 0) {
            delete validationErrors.actions;
        }

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return false;
        } else {
            return true;
        }
    }

    return (
        <>
            <Breadcrumb className="breadCrumb" items={triggerIdForEdit ? [{ title: "Triggers" }, { title: "Edit Triggers" }] : [{ title: "Triggers" }, { title: "Add Trigger" }]} />
            <Row className="addShortcut">
                <Col span={12}>
                    <div>
                        <Form wrapperCol={{ span: 14 }} layout="horizontal" initialValues={{ size: "default" }} size={"default"}>
                            <Form.Item label="Trigger Brand">
                                <Select value={triggerBrand} name="triggerBrand" id="triggerBrand" onChange={onBrandSelectHandler} className={errors.triggerBrand ? "has-error" : ""}>
                                    {brands.length > 0 ? brands.map((brand, index) => {
                                        return (
                                            <Option value={brand._id} key={index}>{brand.brandname}</Option>
                                        )
                                    }) : null}
                                </Select>
                                {errors.triggerBrand ? <span className="error-message">{errors.triggerBrand}</span> : null}
                            </Form.Item>
                            <Form.Item label="Trigger Status">
                                <Checkbox onChange={enableHandler} name="status" id="status" checked={status}>
                                    Enable Trigger
                                </Checkbox>
                                {/* <p> 1 of 2 Triggers used. Upgrade now to increase the feature limit. </p> */}
                            </Form.Item>
                            <Form.Item label="Trigger Name">
                                <Input type="text" name="name" id="name" value={triggerName} className={errors.triggerName ? "has-error" : ""} onChange={onChangeHandler} />
                                {errors.triggerName ? <span className="error-message">{errors.triggerName}</span> : null}
                            </Form.Item>
                            <Form.Item label="Trigger Description">
                                <TextArea rows={4} name="message" id="message" value={triggerMessage} onChange={onChangeHandler} className={errors.triggerMessage ? "has-error" : ""} />
                                {errors.triggerMessage ? <span className="error-message">{errors.triggerMessage}</span> : null}
                            </Form.Item>
                            {/* <Form.Item label="Fire only once per visitor">
                                <Checkbox onChange={enableHandler} name="fireOnce" id="fireOnce" checked={fireOnce}>
                                    Each visitor will receive this message only once
                                </Checkbox>
                            </Form.Item> */}
                            <hr />
                            <div className="customHeader">
                                <h4> Customize trigger</h4>
                            </div>
                            {size == 1 ? (
                                <>
                                    <Form.Item label="Run trigger">
                                        <Select value={runTrigger} name="runTrigger" id="runTrigger" onChange={onRunTriggerSelectHandler}>
                                            <Option value="page_enter">When a visitor has loaded the chat widget</Option>
                                            {/* <Option value="chat_message">When a chat message is sent</Option> */}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item label="Check conditions">
                                        <Select defaultValue={checkCondition} name="checkCondition" id="checkCondition" onChange={onCheckConditionSelectHandler}>
                                            <Option value="and">Check all of the following conditions</Option>
                                            {/* <Option value="or">Check any of the following conditions</Option> */}
                                        </Select>
                                    </Form.Item>
                                    <div className="optionInput">
                                        {conditions.map((val, i) => (
                                            <div className="mainAddInput" key={'conditions' + i}>
                                                <div className="addInput">
                                                    <div className="conditionContent">
                                                        <Form.Item>
                                                            <Select value={conditions[i].conditionVal1 !== undefined && conditions[i].conditionVal1 !== null && conditions[i].conditionVal1 !== "" ? conditions[i].conditionVal1 + '|' + i : "Select condition"} onChange={onSelectConditionHandler} className={errors.conditions ? (errors.conditions[errors.conditions.findIndex(x => x.index == i)]?.conditionVal1 ? "has-error" : "") : null}>
                                                                {conditionsData.length > 0 ? conditionsData.map((con, index) => {
                                                                    return (
                                                                        <OptGroup key={'conditionsData' + index} label={con.name}>
                                                                            {con.conditions.length > 0 ? con.conditions.map((val, newIndex) => {
                                                                                return (
                                                                                    <Option key={'conditionOptions' + index + newIndex} value={val.name + '|' + i}>{val.name}</Option>
                                                                                )
                                                                            }) : null}
                                                                        </OptGroup>
                                                                    )
                                                                }) : null}
                                                            </Select>
                                                            {errors.conditions ? (errors.conditions[errors.conditions.findIndex(x => x.index == i)]?.conditionVal1 ? <span className="error-message">{errors.conditions[errors.conditions.findIndex(x => x.index == i)]?.conditionVal1}</span> : null) : null}
                                                        </Form.Item>
                                                        {conditionsField1[i]?.type == "select" ?
                                                            <Form.Item>
                                                                <Select value={conditions[i].conditionVal2 !== "" && conditions[i].conditionVal2 !== null && conditions[i].conditionVal2 !== undefined ? conditions[i].conditionVal2 + '|' + i : conditionsField1[i].options[0]} onChange={onConditionOperatorsSelectChange} className={errors.conditions ? (errors.conditions[errors.conditions.findIndex(x => x.index == i)]?.conditionVal2 ? "has-error" : "") : null}>
                                                                    {conditionsField1[i].options ? conditionsField1[i].options.map((opt, ind) => {
                                                                        return (
                                                                            <Option value={opt.value + '|' + i} key={'conditionOperators' + ind} > {opt.label}</Option>
                                                                        )
                                                                    }) : null}
                                                                </Select>
                                                                {errors.conditions ? (errors.conditions[errors.conditions.findIndex(x => x.index == i)]?.conditionVal2 ? <span className="error-message">{errors.conditions[errors.conditions.findIndex(x => x.index == i)]?.conditionVal2}</span> : null) : null}
                                                            </Form.Item>
                                                            :
                                                            (conditionsField1[i]?.type == "input" ?
                                                                <Form.Item>
                                                                    <Input onChange={(e) => onConditionOperatorsChange(e, i)} value={conditions[i].conditionVal2} className={errors.conditions ? (errors.conditions[errors.conditions.findIndex(x => x.index == i)]?.conditionVal2 ? "has-error" : "") : null} />
                                                                    {errors.conditions ? (errors.conditions[errors.conditions.findIndex(x => x.index == i)]?.conditionVal2 ? <span className="error-message">{errors.conditions[errors.conditions.findIndex(x => x.index == i)]?.conditionVal2}</span> : null) : null}
                                                                </Form.Item>
                                                                :
                                                                null)
                                                        }
                                                        {conditionsField2[i]?.type == "select" ?
                                                            <Form.Item>
                                                                <Select onChange={onConditionalFieldSelectChange} value={conditions[i].conditionVal3 !== "" && conditions[i].conditionVal3 !== null && conditions[i].conditionVal3 !== undefined ? conditions[i].conditionVal3 + '|' + i : conditionsField2[i].options[0]} className={errors.conditions ? (errors.conditions[errors.conditions.findIndex(x => x.index == i)]?.conditionVal3 ? "has-error" : "") : null}>
                                                                    {conditionsField2[i].options ? conditionsField2[i].options.map((opt, ind) => {
                                                                        return (
                                                                            <Option value={opt.value + '|' + i} key={'conditionVal3' + ind} > {opt.label}</Option>
                                                                        )
                                                                    }) : null}
                                                                </Select>
                                                                {errors.conditions ? (errors.conditions[errors.conditions.findIndex(x => x.index == i)]?.conditionVal3 ? <span className="error-message">{errors.conditions[errors.conditions.findIndex(x => x.index == i)]?.conditionVal3}</span> : null) : null}
                                                            </Form.Item>
                                                            :
                                                            (conditionsField2[i]?.type == "input" ?
                                                                <Form.Item>
                                                                    <Input onChange={(e) => onConditionalFieldChange(e, i)} value={conditions[i].conditionVal3} className={errors.conditions ? (errors.conditions[errors.conditions.findIndex(x => x.index == i)]?.conditionVal3 ? "has-error" : "") : null} />
                                                                    {errors.conditions ? (errors.conditions[errors.conditions.findIndex(x => x.index == i)]?.conditionVal3 ? <span className="error-message">{errors.conditions[errors.conditions.findIndex(x => x.index == i)]?.conditionVal3}</span> : null) : null}
                                                                </Form.Item>
                                                                :
                                                                null)
                                                        }
                                                    </div>
                                                    {i === 0 ? (
                                                        ""
                                                    ) : (
                                                        <MinusOutlined
                                                            className="deleteBtn"
                                                            onClick={() => deleteConditionsFieldHandler(i)}
                                                        />
                                                    )}
                                                    {/* {i === 0 ? <PlusOutlined className="addBtn" onClick={addConditionsFieldHandler} /> : ""} */}
                                                </div>
                                                <p>{conditionsField1[i]?.description !== null && conditionsField1[i]?.description !== "" && conditionsField1[i]?.description !== undefined ? conditionsField1[i]?.description : null}</p>
                                            </div>
                                        ))}
                                    </div>
                                    <Form.Item label="Perform the following actions"></Form.Item>
                                    <div className="optionInput">
                                        {actions.map((val, i) => {
                                            return (
                                                <div className="mainAddInput" key={'actions' + i}>
                                                    <div className="addInput">
                                                        <div className="conditionContent">
                                                            <Form.Item>
                                                                <Select value={actions[i].actionVal1 !== "" && actions[i].actionVal1 !== null && actions[i].actionVal1 !== undefined ? actions[i].actionVal1 + '|' + i : "Select action"} onChange={onSelectActionHandler} className={errors.actions ? (errors.actions[errors.actions.findIndex(x => x.index === i)]?.actionVal1 ? "has-error" : "") : null}>
                                                                    {actionsData.length > 0 ? actionsData.map((actions, index) => {
                                                                        return (
                                                                            <Option key={'actionsData' + index} value={actions.name + '|' + i}>{actions.name} <small>{actions.subname}</small></Option>
                                                                        )
                                                                    }) : null}
                                                                </Select>
                                                                {errors.actions ? (errors.actions[errors.actions.findIndex(x => x.index === i)]?.actionVal1 ? <span className="error-message">{errors.actions[errors.actions.findIndex(x => x.index === i)]?.actionVal1}</span> : null) : null}
                                                            </Form.Item>
                                                            {actionField1[i]?.type === "select" ?
                                                                <Form.Item>
                                                                    <Select value={actions[i].actionVal2 !== "" && actions[i].actionVal2 !== null && actions[i].actionVal2 !== undefined ? actions[i].actionVal2 + '|' + i : actionField1[i]?.options[0]} onChange={onActionFieldOneSelectChange} className={errors.actions ? (errors.actions[errors.actions.findIndex(x => x.index === i)]?.actionVal2 ? "has-error" : "") : null}>
                                                                        {actionField1[i]?.options.length > 0 ? actionField1[i]?.options.map((opt, ind) => {
                                                                            return (
                                                                                <Option value={opt.value + '|' + i} key={'actionOperators' + ind}>{opt.label}</Option>
                                                                            )
                                                                        }) : null}
                                                                    </Select>
                                                                    {errors.actions ? (errors.actions[errors.actions.findIndex(x => x.index === i)]?.actionVal2 ? <span className="error-message">{errors.actions[errors.actions.findIndex(x => x.index === i)]?.actionVal2}</span> : null) : null}
                                                                </Form.Item>
                                                                :
                                                                (actionField1[i]?.type === "input" ?
                                                                    <Form.Item>
                                                                        <Input placeholder={actionField1[i]?.placeholder} value={actions[i].actionVal2} onChange={(e) => onActionFieldOneChange(e, i)} className={errors.actions ? (errors.actions[errors.actions.findIndex(x => x.index === i)]?.actionVal2 ? "has-error" : "") : null} />
                                                                        {errors.actions ? (errors.actions[errors.actions.findIndex(x => x.index === i)]?.actionVal2 ? <span className="error-message">{errors.actions[errors.actions.findIndex(x => x.index === i)]?.actionVal2}</span> : null) : null}
                                                                    </Form.Item>
                                                                    :
                                                                    null)
                                                            }
                                                            {actionField2[i]?.type === "select" ?
                                                                <Form.Item>
                                                                    <Select value={actions[i].actionVal3 !== "" && actions[i].actionVal3 !== null && actions[i].actionVal3 !== undefined ? actions[i].actionVal3 + '|' + i : actionField2[i]?.options[0]} onChange={onActionFieldTwoSelectChange} className={errors.actions ? (errors.actions[errors.actions.findIndex(x => x.index === i)]?.actionVal3 ? "has-error" : "") : null}>
                                                                        {actionField2[i]?.options.length > 0 ? actionField2[i]?.options.map((opt, ind) => {
                                                                            return (
                                                                                <Option value={opt.value + '|' + i} key={'actionFieldTwo' + ind}>{opt.label}</Option>
                                                                            )
                                                                        }) : null}
                                                                    </Select>
                                                                    {errors.actions ? (errors.actions[errors.actions.findIndex(x => x.index === i)]?.actionVal3 ? <span className="error-message">{errors.actions[errors.actions.findIndex(x => x.index === i)]?.actionVal3}</span> : null) : null}
                                                                </Form.Item>
                                                                :
                                                                (actionField2[i]?.type === "input" ?
                                                                    <Form.Item>
                                                                        <Input placeholder={actionField2[i]?.placeholder} value={actions[i].actionVal3} onChange={(e) => onActionFieldTwoChange(e, i)} className={errors.actions ? (errors.actions[errors.actions.findIndex(x => x.index === i)]?.actionVal3 ? "has-error" : "") : null} />
                                                                        {errors.actions ? (errors.actions[errors.actions.findIndex(x => x.index === i)]?.actionVal3 ? <span className="error-message">{errors.actions[errors.actions.findIndex(x => x.index === i)]?.actionVal3}</span> : null) : null}
                                                                    </Form.Item>
                                                                    :
                                                                    null)
                                                            }
                                                        </div>
                                                        {i === 0 ? (
                                                            ""
                                                        ) : (
                                                            <MinusOutlined
                                                                className="deleteBtn"
                                                                onClick={() => deleteActionsFieldHandler(i)}
                                                            />
                                                        )}
                                                        {/* {i === 0 ? <PlusOutlined className="addBtn" onClick={addActionsFieldHandler} /> : ""} */}
                                                    </div>
                                                    <p>{actionField1[i]?.description !== null && actionField1[i]?.description !== "" ? actionField1[i]?.description : null}</p>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </>
                            ) : (
                                <Form.Item label="Message">
                                    <TextArea rows={7} />
                                </Form.Item>
                            )}
                            <Button onClick={onSubmithandler}>Update</Button>
                        </Form>
                    </div>
                </Col>
                {/* <Col span={12}>
          <div className="quickTips">
            <h6>Quick tips</h6>
            <video width="250" controls>
              <source src="" />
            </video>
            <h3>Create triggers</h3>
            <p>
              Triggers let you create automated actions based on specific
              criteria. For example, you can create a trigger to assist visitors
              who get stuck on a particular page of your website. Learn more.
              Let's say a visitor is on your product page for more than 30
              seconds, with a trigger you can automatically ask them, “Would you
              like more information about the product?”.
            </p>
          </div>
        </Col> */}
            </Row >
        </>
    );
};

export default EditTriggers;
