import { Alert, Breadcrumb, Button, Checkbox, Col, Form, Input, Row, message, InputNumber, Dropdown, Space, Switch, Select } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createPackage, fetchChildSidebars, getPackages, getPlans, getSidebar } from '../../redux/Packages/PackagesActions';
import { useLocation } from 'react-router-dom';
import { DownOutlined, UserOutlined } from '@ant-design/icons';

const CreatePackage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const usertoken = useSelector((state) => state.Auth.token);
    const [form] = Form.useForm();
    const [checked, setChecked] = useState(false);
    const [isActive, setisActive] = useState(false);
    const plans = useSelector((state) => state.PackagesReducer.plans);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const sidebarFetch = useSelector((state) => state.PackagesReducer.sidebarFetch);
    const fetchchildSidebar = useSelector((state) => state.PackagesReducer.fetchchildSidebar);
    const [sidebarId, setSidebarId] = useState(null);
    const [parentChildPermissions, setParentChildPermissions] = useState({});
    const [selectedPermissions, setSelectedPermissions] = useState([]);

    const submitHandler = (values) => {
        // console.log("Selected Permissions:", selectedPermissions);
        const data = {
            planTypeId: selectedPlan?._id,
            packageSku: values.packageSku,
            packageTitle: values.packageTitle,
            subTitle: values.subTitle,
            shortDesc: values.shortDesc,
            packagePrice: values.packagePrice,
            brandCount: values.brandCount,
            packageDuration: values.packageDuration,
            userCount: values.userCount,
            packageStatus: checked,
            isActive: isActive,
            packageDescription: values.packageDescription,
            permissions: selectedPermissions,
            child_permissions: selectedPermissions
                .map((parentId) => {
                    const selectedChilds = parentChildPermissions[parentId] || [];
                    const childPermissions = fetchchildSidebar?.data?.filter(
                        (item) => item.sidebar_module_id === parentId
                    );
                    return childPermissions
                        .filter((child) => selectedChilds.includes(child._id))
                        .map((child) => ({
                            parent_id: parentId,
                            child_id: child._id,
                        }));
                })
                .flat(),
        };

        console.log("Data to be submitted:", data);
        dispatch(createPackage(data, usertoken, navigateAfterSuccess));
    };

    const navigateAfterSuccess = () => {
        message.success('Successfully Created');
        navigate('/packages', { state: { data: location?.state?.data } });
    }
    // 
    useEffect(() => {
        dispatch(getPlans(usertoken));
    }, [dispatch, usertoken]);

    const handleMenuClick = (e) => {
        const plan = plans.find(plan => plan._id === e.key);
        message.info(`Selected plan: ${plan.title}`);
        setSelectedPlan(plan);
        form.setFieldsValue({ planTypeId: plan._id });
    };

    const items = plans.map(plan => ({
        label: plan.title,
        key: plan._id,
    }));

    const menuProps = {
        items,
        onClick: handleMenuClick,
    };

    // Options
    const options = [];
    for (let i = 10; i < 36; i++) {
        options.push({
            label: i.toString(36) + i,
            value: i.toString(36) + i,
        });
    }

    // Parent Permisssion
    useEffect(() => {
        dispatch(getSidebar());
    }, [dispatch]);

    useEffect(() => {
        if (sidebarFetch?.data) {
            const permissions = sidebarFetch.data.map(item => item._id);
            setSelectedPermissions(permissions);
        }
    }, [sidebarFetch]);

    const handleChange = (value) => {
        console.log(`selected ${value}`);
        setSidebarId(value);
        setSelectedPermissions(value);
    };

    useEffect(() => {
        if (sidebarId) {
            const data = { sidebar_module_id: sidebarId };
            dispatch(fetchChildSidebars(data));
        }
    }, [sidebarId, dispatch]);

    const optionsPermission = sidebarFetch?.map(item => ({
        label: item.title,
        value: item._id,
    })) || [];


    const handleChildPermissionChange = (parentId, selectedChildPermissions) => {
        const updatedParentChildPermissions = { ...parentChildPermissions };
        updatedParentChildPermissions[parentId] = selectedChildPermissions;
        setParentChildPermissions(updatedParentChildPermissions);
    };
    return (
        <>
            <Breadcrumb className="breadCrumb" items={[{ title: "Plans & Packages" }, { title: "Packages" }, { title: "Create Package" }]} />
            <Row className="addShortcut">
                <Col span={24}>
                    <div>
                        <Form
                            form={form}
                            name="control-hooks"
                            labelCol={{ span: 4 }}
                            layout="horizontal"
                            className="ticketForm"
                            onFinish={submitHandler}
                        >
                            <Form.Item
                                wrapperCol={{ span: 8 }}
                                label="Plan"
                            >
                                <Dropdown menu={menuProps}>
                                    <Button>
                                        <Space>
                                            {selectedPlan ? selectedPlan.title : "Select Plan"}
                                            <DownOutlined />
                                        </Space>
                                    </Button>
                                </Dropdown>
                            </Form.Item>
                            <Form.Item
                                name="packageSku"
                                wrapperCol={{ span: 8 }}
                                label="Package SKU"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter SKU",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="packageTitle"
                                wrapperCol={{ span: 8 }}
                                label="Package Title"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter package title!",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="subTitle"
                                wrapperCol={{ span: 8 }}
                                label="Sub Title"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter sub title!",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="shortDesc"
                                wrapperCol={{ span: 8 }}
                                label="Short Description"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter package short description!",
                                    },
                                ]}
                            >
                                <TextArea rows={3} />
                            </Form.Item>
                            <Form.Item
                                name="packagePrice"
                                wrapperCol={{ span: 8 }}
                                label="Price"
                                rules={[
                                    { required: true, type: 'number', message: "Please enter a valid price!" },
                                    { type: 'number', min: 1, message: "Price must be greater than 0!" },
                                ]}
                            >
                                <InputNumber min={1} style={{ width: '100%' }} />
                            </Form.Item>
                            <Form.Item
                                name="brandCount"
                                wrapperCol={{ span: 8 }}
                                label="Brands (No. of brands)"
                                rules={[
                                    {
                                        required: true,
                                        type: "number",
                                        message: "Please enter package brands count!",
                                    },
                                ]}
                            >
                                <InputNumber min={1} style={{ width: '100%' }} />
                            </Form.Item>
                            <Form.Item
                                name="packageDuration"
                                wrapperCol={{ span: 8 }}
                                label="Duration (In Days)"
                                rules={[
                                    {
                                        required: true,
                                        type: 'number',
                                        message: "Please enter package duration!",
                                    }
                                ]}
                            >
                                <InputNumber min={1} style={{ width: '100%' }} />
                            </Form.Item>
                            <Form.Item
                                name="userCount"
                                wrapperCol={{ span: 8 }}
                                label="Users (No. of users)"
                                rules={[
                                    {
                                        required: true,
                                        type: "number",
                                        message: "Please enter package users count!",
                                    },
                                ]}
                            >
                                <InputNumber min={1} style={{ width: '100%' }} />
                            </Form.Item>
                            <Form.Item wrapperCol={{ span: 8 }} label="Package Status">
                                <Switch checked={checked} onChange={(value) => setChecked(value)} />
                            </Form.Item>

                            <Form.Item
                                name="packageDescription"
                                wrapperCol={{ span: 8 }}
                                label="Description"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter package description!",
                                    },
                                ]}
                            >
                                <TextArea rows={3} />
                            </Form.Item>

                            <Form.Item
                                name="permissions"
                                wrapperCol={{ span: 8 }}
                                label="Permissions"
                            >
                                <Select
                                    mode="multiple"
                                    allowClear
                                    style={{ width: '100%' }}
                                    placeholder="Please select parent permissions"
                                    value={selectedPermissions}
                                    onChange={handleChange}
                                    options={optionsPermission}
                                />
                            </Form.Item>

                            {sidebarId && sidebarId.length > 0 && sidebarId.map((parentId) => {
                                const parent = fetchchildSidebar?.sidebar?.find(item => item._id == parentId);
                                const childPermissions = fetchchildSidebar?.data?.filter(item => item.sidebar_module_id == parentId);

                                return (
                                    <div key={parentId}>
                                        <p><strong>{parent?.title || 'No Parent Title'}</strong></p>
                                        <Checkbox.Group
                                            style={{ width: '100%' }}
                                            value={parentChildPermissions[parentId] || []}
                                            onChange={(selectedChildren) => handleChildPermissionChange(parentId, selectedChildren)}
                                        >
                                            {childPermissions?.map((child) => (
                                                <Checkbox key={child._id} value={child._id}>{child.child_title}</Checkbox>
                                            ))}
                                        </Checkbox.Group>
                                    </div>
                                );
                            })}


                            <div className="topbtn">
                                <Button className="back-btn" onClick={() => navigate('/packages', { state: { data: location?.state?.data } })}>Back</Button>
                                <Button type="primary" className="align-right" htmlType="submit">Save</Button>
                            </div>
                        </Form>
                    </div>
                </Col>
            </Row >
        </>
    );
}

export default CreatePackage;
