import React from "react";
import EmailViewAndReplyTopHeader from "../emailviewandreplytopheader/EmailViewAndReplyTopHeader";
import { BiSortAlt2 } from "react-icons/bi";
import { MdLocalPrintshop } from "react-icons/md";
import { Tooltip } from "antd";
import EmailTempViewAndReply from "../emailtempviewandreply/EmailTempViewAndReply";
import EmailReplyAllAndViewSend from "../emailreplyallandviewsend/EmailReplyAllAndViewSend";

const EmailViewAndReply = () => {
  return (
    <div className="email-view-and-reply-wrapper">
      <EmailViewAndReplyTopHeader />
      <div className="overflow-view-and-reply">
        <div className="email-head-main">
          <div className="left">
            <h2>Trip Reminder. Thank you for flying with us!</h2>
            <span className="inbox">Inbox</span>
            <span className="important">Important</span>
          </div>
          <div className="right">
            <Tooltip title="Sort" placement="top">
              <div className="icons">
                <BiSortAlt2 />
              </div>
            </Tooltip>

            <Tooltip title="Print" placement="top">
              <div className="icons">
                <MdLocalPrintshop />
              </div>
            </Tooltip>
          </div>
        </div>

        <EmailTempViewAndReply name={"Emma Smith"} />
        <EmailTempViewAndReply name={"Max Smith"} />
        <EmailTempViewAndReply name={"Sean Bean"} />

        <EmailReplyAllAndViewSend />
      </div>
    </div>
  );
};

export default EmailViewAndReply;
