import { message } from "antd";
import { Get, Post } from "../../helpers/apicalls/apicalls";
import * as actionTypes from "./FeaturesTypes";

export const createFeatures = (data, token) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.CREATE_FEATURE_LOADING });
        Post('/agents/user/create-feature', data, token)
            .then((res) => {
                if (res?.status) {
                    dispatch({ type: actionTypes.CREATE_FEATURE_SUCCESS });
                } else {
                    dispatch({
                        type: actionTypes.CREATE_FEATURE_FAILED,
                    });
                    message.error(res?.message);
                }
            })
            .catch((err) => {
                dispatch({
                    type: actionTypes.CREATE_FEATURE_FAILED,
                });
            })
    }
}

export const getFeatures = (data) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.GET_FEATURE_LOADING });
        Post('/agents/user/get-feature', data)
            .then((res) => {
                if (res?.status) {
                    dispatch({ type: actionTypes.GET_FEATURE_SUCCESS, payload: res?.data });
                    // afterSuccess();
                } else {
                    dispatch({
                        type: actionTypes.GET_FEATURE_FAILED,
                    });
                    message.error(res?.message);
                }
            })
            .catch((err) => {
                dispatch({
                    type: actionTypes.GET_FEATURE_FAILED,
                });
            })
    }
}

export const updateFeatures = (data, token) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.GET_FEATURE_LOADING });
        Post('/agents/user/update-feature-status', data, token)
            .then((res) => {
                if (res?.status) {
                    dispatch({ type: actionTypes.GET_FEATURE_SUCCESS, payload: res?.data });
                    // afterSuccess();
                } else {
                    dispatch({
                        type: actionTypes.GET_FEATURE_FAILED,
                    });
                    message.error(res?.message);
                }
            })
            .catch((err) => {
                dispatch({
                    type: actionTypes.GET_FEATURE_FAILED,
                });
            })
    }
}


