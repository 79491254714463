import { Tooltip } from "antd";
import React from "react";
import { FaRegTrashAlt } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import { IoArrowBack } from "react-icons/io5";
import { LuArrowRightToLine } from "react-icons/lu";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import { RiSpam2Fill } from "react-icons/ri";
import { BiSolidCopy } from "react-icons/bi";

const EmailViewAndReplyTopHeader = () => {
  return (
    <div className="top-header">
      <div className="left">
        <Tooltip title="Back" placement="top">
          <div className="icons">
            <IoArrowBack />
          </div>
        </Tooltip>
        <Tooltip title="Archive" placement="top">
          <div className="icons">
            <IoMdMail />
          </div>
        </Tooltip>
        <Tooltip title="Spam" placement="top">
          <div className="icons">
            <RiSpam2Fill />
          </div>
        </Tooltip>
        <Tooltip title="Delete" placement="top">
          <div className="icons">
            <FaRegTrashAlt />
          </div>
        </Tooltip>
        <Tooltip title="Mark as read" placement="top">
          <div className="icons">
            <BiSolidCopy />
          </div>
        </Tooltip>
        <Tooltip title="Move" placement="top">
          <div className="icons">
            <LuArrowRightToLine />
          </div>
        </Tooltip>
      </div>
      <div className="right">
        <span>1 - 50 of 235</span>
        <Tooltip title="Previous message" placement="top">
          <div className="icons">
            <MdArrowBackIos className="back" />
          </div>
        </Tooltip>

        <Tooltip title="Next message" placement="top">
          <div className="icons">
            <MdArrowForwardIos className="next" />
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default EmailViewAndReplyTopHeader;
