import React, { useEffect, useState } from 'react';
import { Button, Switch, Form, Input, Table, Space, Modal, Spin } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { createSidebar, getSidebar, updateParentSidebar } from '../../redux/Packages/PackagesActions';
import ChildSidebarModal from './ChildSidebarModal';

export default function PackagesFeature() {
    const dispatch = useDispatch();
    const sidebarCreateLoading = useSelector((state) => state.PackagesReducer.sidebarCreateLoading);
    const sidebarFetch = useSelector((state) => state.PackagesReducer.sidebarFetch);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isChildModalOpen, setIsChildModalOpen] = useState(false);
    const [parentTitle, setParentTitle] = useState('');
    const [selectedSidebarId, setSelectedSidebarId] = useState(null);
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        dispatch(getSidebar());
    }, [dispatch]);

    useEffect(() => {
        if (sidebarFetch && sidebarFetch.length > 0) {
            setTableData(sidebarFetch.map(item => ({
                key: item._id || Math.random().toString(36).substr(2, 9), // Fallback for missing `_id`
                title: item.title || "Untitled",
                status: item.status === true,
            })));
        }
    }, [sidebarFetch]);

    const onFinish = async (values) => {
        try {
            await dispatch(createSidebar(values));
            const newSidebar = {
                key: Date.now(),
                title: values.title,
                status: values.status,
            };
            setTableData(prevData => [...prevData, newSidebar]);
            setIsModalOpen(false); // Close the modal
        } catch (error) {
            console.error("Failed to create sidebar:", error);
        }
    };
    const onChange = (checked, sidebarId) => {
        const updatedTableData = tableData.map(item =>
            item.key === sidebarId ? { ...item, status: checked } : item
        );
        setTableData(updatedTableData);
        const data = {
            sidebar_id: sidebarId,
            status: checked,
        };
        dispatch(updateParentSidebar(data));
    };

    const initialValues = {
        title: sidebarFetch?.[0]?.title || '',
        status: sidebarFetch?.[0]?.status === true,
    };

    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status, record) => (
                <Switch
                    checked={status}
                    onChange={(checked) => onChange(checked, record.key)}
                />
            ),
        },
        {
            title: 'Action',
            key: 'action',
            render: (item, record) => (
                <Space size="middle">
                    <a onClick={() => handleCreateChildSidebar(record.title, record.key)}>Edit / Add Child Sidebar</a>
                </Space>
            ),
        },
    ];

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCreateChildSidebar = (title, id) => {
        setParentTitle(title);
        setSelectedSidebarId(id);
        setIsChildModalOpen(true);
    };

    const handleChildModalClose = () => {
        setIsChildModalOpen(false);
    };

    return (
        <>
            <div className="topbtn">
                <Button type="primary" onClick={showModal}>
                    Create Sidebar
                </Button>
            </div>
            <Modal
                title="Create Sidebar"
                open={isModalOpen}
                onCancel={() => setIsModalOpen(false)}
                footer={null}
            >
                <Form
                    name="basic"
                    style={{ maxWidth: 600 }}
                    initialValues={initialValues}
                    onFinish={onFinish}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Title"
                        name="title"
                        rules={[{ required: true, message: 'Please enter title' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Status"
                        name="status"
                        valuePropName="checked"
                        rules={[{ required: true, message: 'Please enter status' }]}
                    >
                        <Switch />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={sidebarCreateLoading}>
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>

            {/* Show loading state if sidebarFetch is empty */}
            {sidebarFetch.length == 0 ? (
                <Spin tip="Loading..." />
            ) : (
                <Table columns={columns} dataSource={tableData} pagination={false} />
            )}

            <ChildSidebarModal
                visible={isChildModalOpen}
                onClose={handleChildModalClose}
                onCreate={handleChildModalClose}
                parentTitle={parentTitle}
                sidebarId={selectedSidebarId}
            />
        </>
    );
}
