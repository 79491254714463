import { DownloadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { useEffect, useRef } from "react";
import { downloadBufferFile, downloadBufferFileWithType, fileChanger } from "../../helpers/fileChanger";

export const Downloader = ({ file, filename, }) => {
  async function downloadFile() {
    try {
      // Fetch the file as a Blob
      const response = await fetch(file.url);
      if (!response.ok) {
        throw new Error("Failed to fetch the file.");
      }
      const blob = await response.blob();

      // Create a Blob URL
      const blobUrl = URL.createObjectURL(blob);

      // Create an anchor element for download
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = file.filename; // Set the desired filename
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Revoke the Blob URL to free memory
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  }
  return (
    <>
      <Button onClick={() => downloadFile()}><DownloadOutlined /></Button>
      {/* <a style={{ display: "none" }} ref={link} href={url} download={filename}>
        Table export
      </a> */}
    </>
  );
};
