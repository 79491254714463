export const CREATE_FEATURE_LOADING = "CREATE_FEATURE_LOADING";
export const CREATE_FEATURE_SUCCESS = "CREATE_FEATURE_SUCCESS";
export const CREATE_FEATURE_FAILED = "CREATE_FEATURE_FAILED";

export const GET_FEATURE_LOADING = "GET_FEATURE_LOADING";
export const GET_FEATURE_SUCCESS = "GET_FEATURE_SUCCESS";
export const GET_FEATURE_FAILED = "GET_FEATURE_FAILED";

export const UPDATE_FEATURE_LOADING = "UPDATE_FEATURE_LOADING";
export const UPDATE_FEATURE_SUCCESS = "UPDATE_FEATURE_SUCCESS";
export const UPDATE_FEATURE_FAILED = "UPDATE_FEATURE_FAILED";