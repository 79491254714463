import { Avatar, Button, Input, Select, Tag } from "antd";
import React, { useRef, useState } from "react";
import UseRandomColorStyle from "../userandomcolorstyle/UseRandomColorStyle";
import { CloseOutlined } from "@ant-design/icons";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { ImAttachment } from "react-icons/im";

const OPTIONS = [
  {
    name: "Emma Smith",
    email: "emma.smith@mailinator.com",
  },
  {
    name: "Max Smith",
    email: "max.smith@mailinator.com",
  },
  {
    name: "Ana Crown",
    email: "ana.crown@mailinator.com",
  },
  {
    name: "Francis Mitcham",
    email: "francis.mitcham@mailinator.com",
  },
  {
    name: "Emma Smith",
    email: "emma.smith@mailinators.com",
  },
  {
    name: "Max Smith",
    email: "max.smith@mailinators.com",
  },
  {
    name: "Ana Crown",
    email: "ana.crown@mailinators.com",
  },
  {
    name: "Francis Mitcham",
    email: "francis.mitcham@mailinators.com",
  },
  {
    name: "Emma Smith",
    email: "emma.smith@mailinatorss.com",
  },
  {
    name: "Max Smith",
    email: "max.smith@mailinatorss.com",
  },
  {
    name: "Ana Crown",
    email: "ana.crown@mailinatorss.com",
  },
  {
    name: "Francis Mitcham",
    email: "francis.mitcham@mailinatorss.com",
  },
];

const EmailComposeEmail = () => {
  const fileInputRef = useRef(null);
  const { style } = UseRandomColorStyle();

  const [selectedItems, setSelectedItems] = useState([]);
  const [ccMail, setCcMail] = useState(false);
  const [bccMail, setBccMail] = useState(false);
  const [content, setContent] = useState("");

  const handleChange = (value) => {
    setSelectedItems(value);
  };

  const handleQuillChange = (value) => {
    setContent(value);
  };

  const getInitials = (name = "") => {
    const words = name.split(" ");

    if (words.length >= 3) {
      return `${words[0].charAt(0).toUpperCase()}${words[words.length - 1]
        .charAt(0)
        .toUpperCase()}`;
    } else {
      return words.map((word) => word.charAt(0).toUpperCase()).join("");
    }
  };

  const filteredOptions = OPTIONS.filter(
    (option) => !selectedItems.includes(option.email)
  );

  const tagRender = (props) => {
    const { value, onClose } = props;
    const selectedUser = OPTIONS.find((item) => item.email === value);

    return (
      <Tag
        closable
        onClose={(e) => {
          e.stopPropagation();
          onClose();
        }}
        style={{
          display: "flex",
          alignItems: "center",
          gap: 8,
          margin: "4px 4px",
          padding: "4px 7px",
        }}
      >
        <Avatar size="small" style={{ ...style }}>
          {getInitials(selectedUser?.name)}
        </Avatar>
        <span>{selectedUser?.name}</span>
      </Tag>
    );
  };

  const handleAddAll = () => {
    const allEmails = OPTIONS.map((opt) => opt.email);
    const newSelectedItems = Array.from(
      new Set([...selectedItems, ...allEmails])
    );
    setSelectedItems(newSelectedItems);
  };

  const onCcClick = () => {
    setCcMail(true);
  };

  const onBccClick = () => {
    setBccMail(true);
  };

  const handleIconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      console.log("File selected:", file);
    }
  };

  return (
    <>
      <div className="compose-email-wrapper">
        <div className="header">
          <h4>Compose Message</h4>
        </div>

        <div className="compose-to">
          <span className="to">To:</span>
          <Select
            mode="multiple"
            className="compose-multi-dropdown"
            value={selectedItems}
            onChange={handleChange}
            style={{ width: "100%" }}
            optionLabelProp="label"
            tagRender={tagRender}
            popupClassName="compose-multi-popup"
            dropdownRender={(menu) => (
              <div>
                {menu}
                <div className="add-all-option" onClick={handleAddAll}>
                  <span>Add all</span>
                </div>
              </div>
            )}
            options={filteredOptions.map((item) => ({
              value: item.email,
              label: (
                <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
                  <Avatar size="small" style={{ ...style }}>
                    {getInitials(item.name)}
                  </Avatar>
                  <div>
                    <div style={{ fontWeight: "bold" }}>{item.name}</div>
                    <div style={{ color: "gray", fontSize: "12px" }}>
                      {item.email}
                    </div>
                  </div>
                </div>
              ),
            }))}
          />
          <span className="cc" onClick={() => onCcClick()}>
            Cc
          </span>
          <span className="cc" onClick={() => onBccClick()}>
            Bcc
          </span>
        </div>

        {ccMail && (
          <div className="compose-to">
            <span className="to">Cc:</span>
            <Select
              mode="multiple"
              className="compose-multi-dropdown"
              value={selectedItems}
              onChange={handleChange}
              style={{ width: "100%" }}
              optionLabelProp="label"
              tagRender={tagRender}
              popupClassName="compose-multi-popup"
              dropdownRender={(menu) => (
                <div>
                  {menu}
                  <div className="add-all-option" onClick={handleAddAll}>
                    <span>Add all</span>
                  </div>
                </div>
              )}
              options={filteredOptions.map((item) => ({
                value: item.email,
                label: (
                  <div
                    style={{ display: "flex", alignItems: "center", gap: 8 }}
                  >
                    <Avatar size="small" style={{ ...style }}>
                      {getInitials(item.name)}
                    </Avatar>
                    <div>
                      <div style={{ fontWeight: "bold" }}>{item.name}</div>
                      <div style={{ color: "gray", fontSize: "12px" }}>
                        {item.email}
                      </div>
                    </div>
                  </div>
                ),
              }))}
            />

            <div className="close-ccs">
              <CloseOutlined onClick={() => setCcMail(false)} />
            </div>
          </div>
        )}

        {bccMail && (
          <div className="compose-to">
            <span className="to">Bcc:</span>
            <Select
              mode="multiple"
              className="compose-multi-dropdown"
              value={selectedItems}
              onChange={handleChange}
              style={{ width: "100%" }}
              optionLabelProp="label"
              tagRender={tagRender}
              popupClassName="compose-multi-popup"
              dropdownRender={(menu) => (
                <div>
                  {menu}
                  <div className="add-all-option" onClick={handleAddAll}>
                    <span>Add all</span>
                  </div>
                </div>
              )}
              options={filteredOptions.map((item) => ({
                value: item.email,
                label: (
                  <div
                    style={{ display: "flex", alignItems: "center", gap: 8 }}
                  >
                    <Avatar size="small" style={{ ...style }}>
                      {getInitials(item.name)}
                    </Avatar>
                    <div>
                      <div style={{ fontWeight: "bold" }}>{item.name}</div>
                      <div style={{ color: "gray", fontSize: "12px" }}>
                        {item.email}
                      </div>
                    </div>
                  </div>
                ),
              }))}
            />

            <div className="close-ccs">
              <CloseOutlined onClick={() => setBccMail(false)} />
            </div>
          </div>
        )}

        <div className="compose-subject">
          <Input placeholder="Subject" />
        </div>
        <div className="compose-rich-text">
          <ReactQuill
            value={content}
            onChange={handleQuillChange}
            className="quill-text-editor"
            placeholder="Type your text here..."
          />
        </div>
      </div>
      <div className="bottom-btns">
        <div>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileChange}
          />

          <ImAttachment
            onClick={handleIconClick}
            style={{
              fontSize: 20,
              cursor: "pointer",
              color: "grey",
              marginTop: 6,
            }}
          />
        </div>
        <Button type="primary" className="send-btn">
          Send
        </Button>
      </div>
    </>
  );
};

export default EmailComposeEmail;
