import React, { useEffect, useMemo, useState } from 'react';
import { Button, Switch, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import AddModal from './addModal';
import { getFeatures, updateFeatures } from '../../redux/Features/FeaturesActions';

export default function Features() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const dispatch = useDispatch();
    const getFeaturesData = useSelector((state) => state.FeaturesReducer.getFeaturesData);
    const getLoading = useSelector((state) => state.FeaturesReducer.getLoading);
    const usertoken = useSelector((state) => state.Auth.token);
    const location = useLocation();
    const packageId = location.state?.data?.packageId;

    useEffect(() => {
        if (packageId) {
            dispatch(getFeatures({ package_id: packageId }, usertoken));
        }
    }, [packageId, usertoken, dispatch]);

    // Columns definition
    const columns = useMemo(() => [
        { title: 'Serial', dataIndex: 'serial' },
        { title: 'Title', dataIndex: 'title' },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            render: (createdAt) => new Date(createdAt).toLocaleString(),
        },
        {
            title: 'Active',
            dataIndex: 'status',
            render: (status, record) => (
                <Switch
                    checked={status}
                    onChange={(checked) => handleStatusChange(checked, record)}
                />
            ),
        },
    ], []);

    const dataSource = useMemo(() => (
        getFeaturesData?.map((feature, index) => ({
            key: index,
            serial: index + 1,
            title: feature.title,
            status: feature.status,
            createdAt: feature.createdAt,
            feature_id: feature._id,
        }))
    ), [getFeaturesData]);

    const handleStatusChange = (checked, record) => {
        const updatedData = { ...record, status: checked };
        dispatch(updateFeatures(updatedData));
        if (packageId) {
            dispatch(getFeatures({ package_id: packageId }, usertoken));
        }
    };

    return (
        <>
            <AddModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                packageId={packageId}
                onSuccess={() => dispatch(getFeatures({ package_id: packageId }, usertoken))}
            />
            <div className="topbtn" style={{ marginBottom: '20px' }}>
                <Button type="primary" onClick={() => setIsModalOpen(true)}>
                    Create Features
                </Button>
            </div>
            <Table
                loading={getLoading}
                pagination={false}
                columns={columns}
                dataSource={dataSource}
            />
        </>
    );
}
