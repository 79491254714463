import {
  AppstoreOutlined,
  HomeOutlined,
  SettingOutlined,
} from "@ant-design/icons";
// import { Badge, Button } from "antd";

export const AdminSideBar = (
  navigate,
  setselectedKey,
) => [
    {
      key: "/",
      icon: <HomeOutlined />,
      label: "Home",
      onClick: () => {
        setselectedKey("/");
        navigate("/");
      },
    },
    {
      key: "/plans-packages",
      icon: <AppstoreOutlined />,
      label: "Plans & Packages",
      // onClick: () => {
      //   setselectedKey("/plans-packages");
      //   navigate("/plans-packages");
      // },
      children: [
        {
          key: "/plans-packages",
          label: "Plan",
          onClick: () => {
            setselectedKey("/plans-packages");
            navigate("/plans-packages");
          },
        },
        // {
        //   key: "/packages",
        //   label: "Packages old",
        //   onClick: () => {
        //     setselectedKey("/packages");
        //     navigate("/packages");
        //   },
        // },
      ]
    },
    {
      key: "/features",
      icon: <AppstoreOutlined />,
      label: "Features",
      onClick: () => {
        setselectedKey("/features");
        navigate("/features");
      },
    },
    {
      key: "/settings",
      icon: <SettingOutlined />,
      label: "Settings",
      children: [
        {
          key: "/personal",
          label: "Personal",
          onClick: () => {
            setselectedKey("/personal");
            navigate("/personal");
          },
        },
        // {
        //   key: "/triggers",
        //   label: "Triggers",
        //   onClick: () => {
        //     navigate("/triggers");
        //   },
        // },
      ],
    },
    {
      key: "/company",
      icon: <AppstoreOutlined />,
      label: "Companies",
      onClick: () => {
        setselectedKey("/company");
        navigate("/company");
      },
    },
    {
      key: "/packages",
      icon: <AppstoreOutlined />,
      label: "Packages Features",
      children: [
        {
          key: "/packages-feature",
          label: "Sidebar",
          onClick: () => {
            setselectedKey("/packages-feature");
            navigate("/packages-feature");
          },
        },
        // {
        //   key: "/triggers",
        //   label: "Triggers",
        //   onClick: () => {
        //     navigate("/triggers");
        //   },
        // },
      ],
    },
    // {
    //   key: "/packages-feature",
    //   icon: <AppstoreOutlined />,
    //   label: "Packages Features",
    //   onClick: () => {
    //     setselectedKey("/packages-feature");
    //     navigate("/packages-feature");
    //   },
    // },
  ];
