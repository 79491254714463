import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  // Table,
  Modal,
  Select,
  // Space,
  Input,
  Form,
  // Alert,
  Row,
  Col,
  Switch,
  Spin,
  Tooltip,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Get_Brands_By_Id } from "../../redux/Brands/BrandsActions";
// import { Add_Users } from "../../redux/Users/UsersActions/";
import { Add_Users, Block_User, GetOverAllUsers } from "../../redux/Users/UsersActions";
import UserEditModal from "./UserEditModal";
import { getActiveDepartments } from "../../redux/Departments/DepartmentsActions";
import { useLocation } from "react-router-dom";
import { EditOutlined } from "@ant-design/icons";
import UserChatDrawer from "../../components/userChatDrawer";
import { SocketContext } from "../../context/socketContext";
import UseRandomColorStyle from "../../components/userandomcolorstyle/UseRandomColorStyle";

const Users = () => {
  const {
    FiltredUers,
    selectedUserChat,
    setSelectedUserChat,
    unSeenChat,
    setUnSeenChat,
    openUserChat,
    setOpenUserChat,
  } = useContext(SocketContext);
  const userData = useSelector((state) => state.Auth.userData);
  const usertoken = useSelector((state) => state.Auth.token);
  const allData = useSelector((state) => state.Brands.data);
  const allUsers = useSelector((state) => state.Users.overAllUsers);
  const isLoading = useSelector((state) => state.Users.isLoadingUserALL);
  const messagesData = useSelector((state) => state.Users.messagesData);
  const departments = useSelector(
    (state) => state.Departments.activeDepartment
  );
  const dispatch = useDispatch();
  const location = useLocation();
  const { style } = UseRandomColorStyle();
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [editedUser, setEditedUser] = useState({});
  const [Error, setError] = useState("");
  // const columns = [
  //   {
  //     title: "Sr #",
  //     dataIndex: "serial",
  //   },
  //   {
  //     title: "Name",
  //     dataIndex: "name",
  //   },
  //   {
  //     title: "Email",
  //     dataIndex: "email",
  //   },
  //   {
  //     title: "Department",
  //     dataIndex: "department",
  //   },
  //   {
  //     title: "Role",
  //     dataIndex: "role",
  //   },
  //   {
  //     title: "Action",
  //     key: "action",
  //     render: (_, record) =>
  //       userData?.role === "superadmin" ? (
  //         <Space size="middle">
  //           <a onClick={() => openEditModalHandler(record.id)}>Edit</a>|{" "}
  //           <a>Delete</a>
  //         </Space>
  //       ) : (
  //         <Space size="middle">
  //           <a onClick={() => openEditModalHandler(record.id)}>Edit</a>
  //         </Space>
  //       ),
  //   },
  // ];

  useEffect(() => {
    const data = {
      id: userData?._id,
      companyId: userData?.companyId,
    };
    dispatch(GetOverAllUsers(data, usertoken));
    if (location?.state?.user_id) {
      openEditModalHandler(location?.state?.user_id);
    }
  }, []);

  useEffect(() => {
    if (location?.state?.user_id) {
      openEditModalHandler(location?.state?.user_id);
    }
  }, [location?.state?.user_id]);

  useEffect(() => {
    const data = {
      companyId: userData?.companyId,
      id: userData?._id
    };
    dispatch(Get_Brands_By_Id(data, usertoken));
    dispatch(getActiveDepartments(data, usertoken));
  }, [open]);

  const allBrands = allData?.map((i) => {
    return {
      label: i?.brandname,
      value: i?._id,
    };
  });

  const allDepartments = departments?.map((i) => {
    return {
      label: i?.name,
      value: i?._id,
    };
  });

  const showModal = () => {
    setOpen(true);
  };

  const afterSuccess = () => {
    form.resetFields();
    setOpen(false);
    const data = {
      id: userData?._id,
      companyId: userData?.companyId,
    };
    dispatch(GetOverAllUsers(data, usertoken));
  };

  const handleOk = (values) => {
    setLoading(true);
    setError("");
    const data = {
      name: values.name,
      role: values.Role,
      sudoName: values.sudo,
      email: values.email,
      password: values.password,
      addedby: userData?._id,
      assignBrands: values.Brand,
      department: values.Department,
      companyId: userData?.companyId,
    };
    dispatch(Add_Users(data, usertoken, afterSuccess, setLoading, setError));
  };

  const handleCancel = () => {
    setOpen(false);
  };

  // const onChange = (pagination, filters, sorter, extra) => {};

  // const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [loading, setLoading] = useState(false);

  // const onSelectChange = (newSelectedRowKeys) => {
  //   setSelectedRowKeys(newSelectedRowKeys);
  // };

  // const rowSelection = {
  //   selectedRowKeys,
  //   onChange: onSelectChange,
  // };

  const options = [
    {
      label: "sub admin",
      value: "subadmin",
    },
    {
      label: "Agent",
      value: "agent",
    },
  ];

  const options2 = [
    {
      label: "Admin",
      value: "admin",
    },
    {
      label: "Sub Admin",
      value: "subadmin",
    },
    {
      label: "Agent",
      value: "agent",
    },
  ];

  // const data = [];
  // for (let i = 0; i < allUsers?.length; i++) {
  //   data.push({
  //     key: i,
  //     id: allUsers[i]._id,
  //     serial: i + 1,
  //     name: allUsers[i]?.name,
  //     email: allUsers[i]?.email,
  //     role: allUsers[i]?.role,
  //     department: allUsers[i]?.depart?.name,
  //   });
  // }

  const openEditModalHandler = (id) => {
    const user = allUsers.find((i) => i._id === id);
    setEditedUser(user);
    setOpenEditModal(true);
  };

  const closeEditModalHandler = (val) => {
    setEditedUser({});
    setOpenEditModal(val);
  };

  const getInitials = (name = "") => {
    const words = name.split(" ");

    if (words.length >= 3) {
      return `${words[0].charAt(0).toUpperCase()}${words[words.length - 1]
        .charAt(0)
        .toUpperCase()}`;
    } else {
      return words.map((word) => word.charAt(0).toUpperCase()).join("");
    }
  };

  const onSendMessageClick = (data) => {
    setOpenUserChat(true);
    setSelectedUserChat(data);
  };
  const onChangeBlock = (checked, data) => {
    const Newdata = {
      isBlocked: checked,
      userId: data?._id,
      adminID: userData?._id
    }
    dispatch(Block_User(Newdata, usertoken));
  }

  return (
    <>
      <UserEditModal
        user={editedUser}
        allBrands={allBrands}
        allDepartments={allDepartments}
        options={options}
        options2={options2}
        role={userData?.role}
        open={openEditModal}
        close={closeEditModalHandler}
      />
      <Modal
        open={open}
        // closable={true}
        title="Add User"
        footer={null}
        onCancel={handleCancel}
      >
        <Form
          form={form}
          name="control-hooks"
          onFinish={handleOk}
          style={{
            maxWidth: 600,
          }}
        >
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: "Please enter name!",
              },
            ]}
          >
            <Input placeholder="Name" />
          </Form.Item>
          <Form.Item
            name="sudo"
            rules={[
              {
                required: true,
                message: "Please enter sudo name!",
              },
            ]}
          >
            <Input placeholder="Sudo Name" />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Please enter email!",
              },
              {
                type: "email",
                message: "This email is not valid!",
              },
            ]}
          >
            <Input placeholder="Email" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please enter password!",
              },
            ]}
          >
            <Input.Password type="password" placeholder="Password" />
          </Form.Item>
          <Form.Item
            name="Role"
            rules={[
              {
                required: true,
                message: "Please select role!",
              },
            ]}
          >
            <Select
              placeholder="Select Role"
              style={{
                width: "100%",
              }}
              options={userData?.role === "superadmin" ? options2 : options}
            />
          </Form.Item>
          <Form.Item
            name="Brand"
            rules={
              userData?.role !== "superadmin" && [
                {
                  required: true,
                  message: "Please select brand(s)!",
                },
              ]
            }
          >
            <Select
              mode="multiple"
              allowClear
              style={{
                width: "100%",
              }}
              placeholder="Select Brand(s)"
              options={allBrands}
            />
          </Form.Item>
          <Form.Item
            name="Department"
            rules={
              userData?.role !== "superadmin" && [
                {
                  required: true,
                  message: "Please select department!",
                },
              ]
            }
          >
            <Select
              allowClear
              style={{
                width: "100%",
              }}
              placeholder="Select Department"
              options={allDepartments}
            />
          </Form.Item>
          <Form.Item>
            <Button
              loading={loading}
              type="primary"
              htmlType="submit"
              style={{
                float: "right",
              }}
            >
              Submit
            </Button>
          </Form.Item>
          <p style={{ textAlign: "center", color: "red" }}>{Error}</p>
        </Form>
      </Modal>

      {/* <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={data}
        onChange={onChange}
      /> */}

      <div className="internal-users-main-wrapper">
        <div className="internal-users-header">
          <p className="all-users">
            All Users{" "}
            {allUsers?.length > 0 && <span>({allUsers?.length})</span>}
          </p>

          {userData?.role != "agent" && (
            <Button type="primary" onClick={showModal} loading={loading}>
              Add User
            </Button>
          )}
        </div>
        <Row className="user-card-row-main">
          {isLoading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "600px",
              }}
            >
              <Spin size="large" />
            </div>
          ) : allUsers?.length > 0 ? (
            allUsers?.map((data, index) => {
              let findOnline = FiltredUers?.find((z) => z?._id === data?._id);
              let findMessages = messagesData?.filter((p) => p?.sender === data?._id);
              return (
                <Col xl={6} key={index}>
                  <div className="user-card-main">
                    {userData?.role === "admin" && (
                      <div className="top">
                        <Tooltip placement="top" title={userData?.user_status ? "Block Agent" : "Unblock Agent"}>
                          <Switch
                            onChange={(checked) => onChangeBlock(checked, data)}
                            defaultChecked={data?.user_status}
                          />
                        </Tooltip>

                        <EditOutlined
                          onClick={() => openEditModalHandler(data?._id)}
                        />
                      </div>
                    )}
                    <div className="image">
                      <h4
                        className={
                          findOnline?.status == 22
                            ? "online"
                            : findOnline?.status == 23
                            ? "away"
                            : "offline"
                        }
                        style={{ ...style }}
                      >
                        {getInitials(data?.name)}
                      </h4>
                    </div>
                    <p className="name">{data?.name}</p>
                    <p className="sudoName">{data?.sudoName}</p>
                    <p className="email">{data?.email}</p>
                    <div className="mid-sec">
                      <div className="mid-inner">
                        <p>Depart.</p>
                        <span>{data?.depart?.name}</span>
                      </div>
                      <div className="mid-inner">
                        <p>Role</p>
                        <span>{data?.role}</span>
                      </div>
                    </div>
                    <div className="btn-main">
                      <Button
                        type={"primary"}
                        className={findMessages?.length > 0 ? "send-message-count" : "send-message"}
                        onClick={() => onSendMessageClick(data)}
                      >
                        {`Send Message ${findMessages?.length > 0 ? `(${findMessages?.length})` : ""}`}
                      </Button>
                    </div>
                  </div>
                </Col>
              );
            })
          ) : (
            <p className="no-record-found">No Record found</p>
          )}

          <UserChatDrawer
            unSeenChat={unSeenChat}
            setUnSeenChat={setUnSeenChat}
            setSelectedUser={setSelectedUserChat}
            selectedUser={selectedUserChat}
            open={openUserChat}
            setOpen={setOpenUserChat}
          />
        </Row>
      </div>
    </>
  );
};

export default Users;
