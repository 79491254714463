import { useEffect, useRef, useState } from "react";

const backgroundColors = ["#FFEEF3", "#FFF8DD", "#DFFFEA", "#F8F5FF"];
const fontColors = ["#f8285a", "#F6C000", "#17C653", "#7239EA"];

const UseRandomColorStyle = () => {
  const [style, setStyle] = useState({});
  const [lastIndex, setLastIndex] = useState(null);
  const hasInitialized = useRef(false);

  const getRandomColorStyle = () => {
    let randomIndex;
    do {
      randomIndex = Math.floor(Math.random() * backgroundColors.length);
    } while (randomIndex === lastIndex);

    setStyle({
      backgroundColor: backgroundColors[randomIndex],
      color: fontColors[randomIndex],
    });
    setLastIndex(randomIndex);
  };

  useEffect(() => {
    if (!hasInitialized.current) {
      getRandomColorStyle();
      hasInitialized.current = true;
    }
  }, []);

  return { style };
};

export default UseRandomColorStyle;
