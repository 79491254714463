import { Breadcrumb, Button, Checkbox, Col, Form, Input, Row, message, InputNumber, Select } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchChildSidebars, getPackageById, getSidebar, updatePackage } from '../../redux/Packages/PackagesActions';
import { useLocation } from 'react-router-dom';

const EditPackage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const usertoken = useSelector((state) => state.Auth.token);
    const [form] = Form.useForm();
    const [checked, setChecked] = useState(false);
    const [isActive, setisActive] = useState(false);
    const packageById = useSelector((state) => state.PackagesReducer.packageById);
    const updatePlan = useSelector((state) => state.PackagesReducer.updatePlan);
    const sidebarFetch = useSelector((state) => state.PackagesReducer.sidebarFetch);
    const [sidebarId, setSidebarId] = useState(null);
    const fetchchildSidebar = useSelector((state) => state.PackagesReducer.fetchchildSidebar);
    const [selectedPermissions, setSelectedPermissions] = useState([]);
    const isLoading = useSelector((state) => state.PackagesReducer.isLoading);

    useEffect(() => {
        let data = {
            packageId: location?.state?.data?.planTypeId,
        };
        dispatch(getPackageById(data, usertoken));
    }, [location, usertoken]);

    useEffect(() => {
        if (packageById) {
            setChecked(packageById?.status || false);
            setisActive(packageById?.isActive || false);
            form.setFieldsValue({
                packageTitle: packageById?.title || '',
                packageSku: packageById?.sku || '',
                packagePrice: packageById?.price || 0,
                packageDuration: packageById?.duration || 0,
                brandCount: packageById?.brandCount || 0,
                userCount: packageById?.userCount || 0,
                packageDescription: packageById?.description || '',
                subTitle: packageById?.subTitle || '',
                shortDesc: packageById?.shortDesc || '',
                permissions: packageById?.permissions || [],
                child_permissions: packageById?.child_permissions || [],
            });
        }
    }, [packageById, form]);

    const submitHandler = (values) => {
        const transformedChildPermissions = values.child_permissions.map((childId) => ({
            parent_id: packageById._id,
            child_id: childId,
        }));

        const data = {
            packageStatus: checked,
            packageId: packageById._id,
            planTypeId: packageById.planTypeId,
            packageTitle: values?.packageTitle,
            packageSku: values?.packageSku,
            packagePrice: values?.packagePrice,
            packageDuration: values?.packageDuration,
            brandCount: values?.brandCount,
            userCount: values?.userCount,
            packageDescription: values?.packageDescription,
            subTitle: values?.subTitle,
            shortDesc: values?.shortDesc,
            permissions: values?.permissions,
            child_permissions: transformedChildPermissions,
        };

        console.log("Payload to API:", data);
        dispatch(updatePackage(data, usertoken, navigateAfterSuccess));
    };

    useEffect(() => {
        if (packageById?.child_permissions) {
            const selectedPermissions = packageById.child_permissions.map((perm) => perm.child_id);
            form.setFieldsValue({ child_permissions: selectedPermissions });
        }
    }, [packageById, form]);


    // console.log("Form values before submit:", updatePlan);
    // console.log("packageById:", packageById);

    const onCheckboxValueChange = (e) => {
        if (e.target.checked) {
            setChecked(true)
        } else {
            setChecked(false)
        }
    }
    const onCheckboxBestValue = (e) => {
        if (e.target.checked) {
            setisActive(true)
        } else {
            setisActive(false)
        }
    }

    const navigateAfterSuccess = () => {
        message.success('Successfully Updated');
        navigate('/packages', { state: { data: packageById.planTypeId } });
    }

    const options = [];
    for (let i = 10; i < 36; i++) {
        options.push({
            label: i.toString(36) + i,
            value: i.toString(36) + i,
        });
    }
    const handleChange = (value) => {
        console.log(`selected ${value}`);
        setSidebarId(value);
    };

    const onChange = (checkedValues) => {
        setSelectedPermissions(checkedValues);
        console.log("Checked Values:", checkedValues);
    };

    // Parent permissions
    useEffect(() => {
        dispatch(getSidebar());
    }, [dispatch]);

    useEffect(() => {
        if (sidebarFetch?.data) {
            const permissions = sidebarFetch.data.map(item => item._id);
            setSelectedPermissions(permissions);
        }
    }, [sidebarFetch]);

    const optionsPermission = sidebarFetch?.map(item => ({
        label: item.title,
        value: item._id,
    })) || [];

    // Child permissions
    useEffect(() => {
        if (sidebarId) {
            const data = { sidebar_module_id: sidebarId };
            dispatch(fetchChildSidebars(data));
        }
    }, [sidebarId, dispatch]);

    const childPermissions = fetchchildSidebar?.data?.map(item => ({
        label: item.child_title,
        value: item._id,
        parentId: item.sidebar_module_id,
    })) || [];

    const parentName = fetchchildSidebar?.sidebar?.map(item => ({
        label: item.title,
        value: item._id,
    })) || [];

    return (
        <>
            <Breadcrumb className="breadCrumb" items={[{ title: "Plans & Packages" }, { title: "Packages" }, { title: "Edit Package" }]} />
            <Row className="addShortcut">
                <Col span={24}>
                    <div>
                        <Form
                            form={form}
                            name="control-hooks"
                            labelCol={{ span: 3 }}
                            layout="horizontal"
                            className="ticketForm"
                            onFinish={submitHandler}
                            onFinishFailed={(errorInfo) => {
                                console.error("Form Submission Failed:", errorInfo);
                            }}
                        >
                            <Form.Item
                                name="packageSku"
                                wrapperCol={{ span: 8 }}
                                label="SKU"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter SKU!",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="packageTitle"
                                wrapperCol={{ span: 8 }}
                                label="Title"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter package title!",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="subTitle"
                                wrapperCol={{ span: 8 }}
                                label="Sub Title"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter package sku!",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="shortDesc"
                                wrapperCol={{ span: 12 }}
                                label="Short Description"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter package short description!",
                                    },
                                ]}
                            >
                                <TextArea rows={3} />
                            </Form.Item>
                            <Form.Item
                                name="packagePrice"
                                wrapperCol={{ span: 8 }}
                                label="Price"
                                rules={[
                                    {
                                        required: true,
                                        type: 'number',
                                        message: "Please enter package price!",
                                    },
                                ]}
                            >
                                <InputNumber min={1} style={{ width: '100%' }} />
                            </Form.Item>
                            <Form.Item
                                name="brandCount"
                                wrapperCol={{ span: 8 }}
                                label="Brands (No. of brands)"
                                rules={[
                                    {
                                        required: true,
                                        type: 'number',
                                        message: "Please enter package brands count!",
                                    },
                                ]}
                            >
                                <InputNumber min={1} style={{ width: '100%' }} />
                            </Form.Item>
                            <Form.Item
                                name="packageDuration"
                                wrapperCol={{ span: 8 }}
                                label="Duration (In Days)"
                                rules={[
                                    {
                                        required: true,
                                        type: 'number',
                                        message: "Please enter package duration!",
                                    },
                                ]}
                            >
                                <InputNumber min={1} style={{ width: '100%' }} />
                            </Form.Item>
                            <Form.Item
                                name="userCount"
                                wrapperCol={{ span: 8 }}
                                label="Users (No. of users)"
                                rules={[
                                    {
                                        required: true,
                                        type: 'number',
                                        message: "Please enter package users count!",
                                    },
                                ]}
                            >
                                <InputNumber min={1} style={{ width: '100%' }} />
                            </Form.Item>
                            <Form.Item
                                wrapperCol={{ span: 8 }}
                                label="Status"
                            >
                                <Checkbox name="packageStatus" id="packageStatus" checked={checked} onChange={onCheckboxValueChange}>
                                    Package Status
                                </Checkbox>
                            </Form.Item>


                            <Form.Item
                                wrapperCol={{ span: 8 }}
                                label="isActive"
                            >
                                <Checkbox name="packageStatus" id="packageStatus" checked={isActive} onChange={onCheckboxBestValue}>
                                    Enable Package
                                </Checkbox>
                            </Form.Item>
                            <Form.Item
                                name="packageDescription"
                                wrapperCol={{ span: 12 }}
                                label="Description"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter package description!",
                                    },
                                ]}
                            >
                                <TextArea rows={3} />
                            </Form.Item>
                            <Form.Item
                                name="permissions"
                                wrapperCol={{ span: 12 }}
                                label="Permissions"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter package permissions!",
                                    },
                                ]}
                            >
                                <Select
                                    mode="tags"
                                    style={{
                                        width: '100%',
                                    }}
                                    placeholder="Tags Mode"
                                    onChange={handleChange}
                                    options={optionsPermission}
                                />
                            </Form.Item>
                            <Form.Item
                                name="child_permissions"
                                wrapperCol={{ span: 12 }}
                                label="Child Permissions"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter package permissions!",
                                    },
                                ]}
                            >
                                <Checkbox.Group
                                    style={{ width: '100%' }}
                                    value={selectedPermissions}
                                    onChange={onChange}
                                >
                                    {parentName.map((parent) => {
                                        const childPermissionsForParent = childPermissions.filter(
                                            (child) => child.parentId == parent.value
                                        );

                                        // // Debugging: Log to verify the filter
                                        // console.log(
                                        //     `Parent: ${parent.label}, Children: `,
                                        //     childPermissionsForParent
                                        // );

                                        return (
                                            <div key={parent.value}>
                                                <h2>{parent.label}</h2>
                                                {childPermissionsForParent.length > 0 ? (
                                                    childPermissionsForParent.map((child) => (
                                                        <Checkbox key={child.value} value={child.value}>
                                                            {child.label}
                                                        </Checkbox>
                                                    ))
                                                ) : (
                                                    <p>No child permissions available</p>
                                                )}
                                            </div>
                                        );
                                    })}
                                </Checkbox.Group>
                            </Form.Item>


                            <div className="topbtn">
                                <Button className="back-btn" onClick={() => navigate('/packages', { state: { data: packageById.planTypeId } })}>Back</Button>
                                <Button type="primary" className="align-right" htmlType="submit">Update</Button>
                            </div>
                        </Form>
                    </div>
                </Col>
            </Row >
        </>
    );
}

export default EditPackage;
