import * as actionTypes from "./FeaturesTypes";

const INTIAL_STATE = {
    createFeatures: [],
    createLoading: false,
    getFeaturesData: [],
    getLoading: false,
    updateFeaturesData: [],
    updateLoading: false,
};

const FeaturesReducer = (state = INTIAL_STATE, action) => {
    const { payload } = action;

    switch (action.type) {
        case actionTypes.CREATE_FEATURE_LOADING:
            return {
                ...state,
                createLoading: true
            };

        case actionTypes.CREATE_FEATURE_SUCCESS:
            return {
                ...state,
                createFeatures: payload,
                createLoading: false
            };

        case actionTypes.CREATE_FEATURE_FAILED:
            return {
                ...state,
                createLoading: false
            };

        case actionTypes.GET_FEATURE_LOADING:
            return {
                ...state,
                getLoading: true
            };

        case actionTypes.GET_FEATURE_SUCCESS:
            return {
                ...state,
                getFeaturesData: payload,
                getLoading: false
            };

        case actionTypes.GET_FEATURE_FAILED:
            return {
                ...state,
                getLoading: false
            };

        case actionTypes.UPDATE_FEATURE_LOADING:
            return {
                ...state,
                updateLoading: true
            };

        case actionTypes.UPDATE_FEATURE_SUCCESS:
            return {
                ...state,
                updateFeaturesData: payload,
                updateLoading: false
            };

        case actionTypes.UPDATE_FEATURE_FAILED:
            return {
                ...state,
                updateLoading: false
            };
        default:
            return state;
    }
}

export default FeaturesReducer;