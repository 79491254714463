import { AutoComplete, Checkbox, Dropdown, Input, Tooltip } from "antd";
import React, { useState } from "react";
import { FaChevronDown, FaRegTrashAlt } from "react-icons/fa";
import { FaArrowsRotate } from "react-icons/fa6";
import { IoMdMail } from "react-icons/io";

const EmailTopHeader = ({ activeTab }) => {
  const [options, setOptions] = useState([]);

  const getRandomInt = (max, min = 0) =>
    Math.floor(Math.random() * (max - min + 1)) + min;

  const searchResult = (query) =>
    new Array(getRandomInt(5))
      .join(".")
      .split(".")
      .map((_, idx) => {
        const category = `${query}${idx}`;
        return {
          value: category,
          label: (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span>
                Found {query} on {category}
              </span>
              <span>{getRandomInt(200, 100)} results</span>
            </div>
          ),
        };
      });

  const readItems = [
    {
      key: "1",
      label: <div>All</div>,
    },
    {
      key: "2",
      label: <div>Read</div>,
    },
    {
      key: "3",
      label: <div>Unread</div>,
    },
    {
      key: "4",
      label: <div>Starred</div>,
    },
    {
      key: "5",
      label: <div>Unstarred</div>,
    },
  ];

  const handleSearch = (value) => {
    setOptions(value ? searchResult(value) : []);
  };

  const onSelect = (value) => {
    console.log("onSelect", value);
  };

  return (
    <div className="top-header">
      <div className="left">
        <Checkbox />
        <Tooltip title="Reload" placement="top">
          <div className="icons">
            <FaArrowsRotate />
          </div>
        </Tooltip>
        <Tooltip title="Archive" placement="top">
          <div className="icons">
            <IoMdMail />
          </div>
        </Tooltip>
        <Tooltip title="Delete" placement="top">
          <div className="icons">
            <FaRegTrashAlt />
          </div>
        </Tooltip>
        <div className="icons">
          <Dropdown
            menu={{ items: readItems }}
            className="read-dropdown"
            overlayClassName="read-overley"
            trigger={"hover"}
          >
            <a onClick={(e) => e.preventDefault()}>
              <FaChevronDown />
            </a>
          </Dropdown>
        </div>
      </div>
      <div className="right">
        <AutoComplete
          popupMatchSelectWidth={252}
          style={{
            width: 250,
            height: "inherit",
          }}
          options={options}
          onSelect={onSelect}
          onSearch={handleSearch}
          size="large"
        >
          <Input.Search
            className="header-searchbar"
            size="large"
            placeholder={`Search ${
              activeTab === 1
                ? "inbox"
                : activeTab === 2
                ? "marked"
                : activeTab === 3
                ? "draft"
                : activeTab === 4
                ? "sent"
                : ""
            }`}
            enterButton
          />
        </AutoComplete>
      </div>
    </div>
  );
};

export default EmailTopHeader;
