import React, { useState, useEffect, useContext, useRef, useMemo } from "react";
import {
  FileTextOutlined,
  UploadOutlined,
  UserOutlined,
  SmileOutlined,
  DownOutlined,
  AudioOutlined,
} from "@ant-design/icons";
// import * as actionTypes from "../../redux/VisitorsJoined/VisitorsJoinedTypes";
import clockImg from "../../assets/image/icons-messages-tick-01.png";
import delevredIcon from "../../assets/image/icons-messages-tick-03.png";
import seenIcon from "../../assets/image/icons-messages-tick-04.png";
import singleCheck from "../../assets/image/icons-messages-tick-02.png";
import {
  Button,
  message,
  Input,
  Avatar,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { SocketContext } from "../../context/socketContext";
import moment from "moment";
import { Downloader } from "../fileDownloader/Downloader";
// import { arrayBufferToBase64, fileChanger } from "../../helpers/fileChanger";
import EmojiPicker from "emoji-picker-react";
import Typingdots from "../typingdots/typingdots";
import { saveVisitorServed } from "../../redux/VisitorsJoined/VisitorsJoinedActions";
// import Recoder from "../audioRecoder/recoder";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import AudioPlayerCustom from "../audioPlayerCustom/AudioPlayerCustom";
import { FaTrashAlt } from "react-icons/fa";

const DrageCurrentChat = ({
  isAnimation,
  setisAnimation,
  messages,
  setMessages,
  currentActiveVisitorData,
}) => {
  const [heightofModal, setheightofModal] = useState(0);
  const [typedmessage, settypedmessage] = useState("");
  const userData = useSelector((state) => state.Auth.userData);
  // const isLoadClient = useSelector((state) => state.VisitorReducer.isLoadingVisitor);
  const servedData = useSelector((state) => state.VisitorReducer.data);
  const dispatch = useDispatch();
  const [typeData, settypeData] = useState(null);
  const AllShortcuts = useSelector((state) => state.Shortcuts.allShortcuts);
  const overAllUsers = useSelector((state) => state.Users.overAllUsers);
  const { Socket } = useContext(SocketContext);
  const [Typing, setTyping] = useState(false);
  const [currentmsg, setcurrentmsg] = useState("");
  // const [selectedImage, setSelectedImage] = useState(null);
  const [UpcomingMessages, setUpcomingMessages] = useState(null);
  const [myIncomingMsgStatus, setmyIncomingMsgStatus] = useState(null);
  const [isEmojiOpen, setisEmojiOpen] = useState(false);
  const [NewEmoji, setNewEmoji] = useState(null);
  const [joindLoading, setjoindLoading] = useState(false);
  const [One_to_One, setOne_to_One] = useState(null);
  const [startRecord, setStartRecord] = useState(false);
  const [audioUrl, setaudioUrl] = useState(null);
  const [audioBlob, setaudioBlob] = useState(null);
  const fileInputRef = useRef(null);
  const recorderControls = useAudioRecorder();
  const [TempFile, setTempFile] = useState(null);

  // const [api, contextHolder] = notification.useNotification();

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const maxSizeInBytes = 5 * 1024 * 1024; // 5 MB
    if (file.size > maxSizeInBytes) {
      message.error("File size exceeds 5MB.");
    } else {
      handleUpload(file);
    }
  };
  const bottomRef = useRef(null);
  const findingTxt = AllShortcuts?.filter(
    (i) =>
      (currentmsg !== ""
        ? i?.shortcut.toLowerCase().includes(currentmsg.toLowerCase())
        : true) &&
      (i?.available_for?.some((t) => t?._id === userData?._id) ||
        i?.available_for?.some((t) => t?._id === "All-Agents"))
  );

  // is Typing
  useEffect(() => {
    if (!Socket) return;
    Socket.on("visitorTyping", (data) => {
      settypeData(data);
    });
  }, [Socket]);

  useEffect(() => {
    if (Typing) {
      setTimeout(() => {
        const modalContent = bottomRef.current.closest(".chatsMsgs");
        if (modalContent) {
          modalContent.scrollTo({
            top: modalContent.scrollHeight,
            behavior: 'smooth'
          });
        }
        setTyping(false);
      }, 1000);
    }
  }, [Typing]);

  useEffect(() => {
    if (typeData !== null) {
      if (typeData?.visitorID == currentActiveVisitorData?.unique_id) {
        setTyping(true);
        settypeData(null);
      }
      else {
        setTyping(false);
        settypeData(null);
      }
    }
  }, [typeData]);

  //Getting Visitor MEssages
  useEffect(() => {
    if (!Socket) return;
    Socket.on("visitorMessages", (data) => {
      setUpcomingMessages(data);
      setisAnimation(false);
    });

    Socket.on("whatVisitorType", (data) => {
      if (data?.visitorID == currentActiveVisitorData?.unique_id) {
        settypedmessage(data?.type);
      }
    });
    // Socket.on("notServedMessages", (data) => {
    //     setLoading(false);
    //     setUpcomingMessages(data);
    // });
    // Socket.on("agentMessageReceived", (data) => {
    //     setmyIncomingMsgStatus(data);
    //     setisAnimation(false);

    // });
    Socket.on("agentMessagefromServer", (data) => {
      setmyIncomingMsgStatus(data);
      setisAnimation(false);

    });

    Socket.emit("getVisitorMsg", {
      convoID: currentActiveVisitorData?.conversationId,
      agentId: userData?._id,
      unique_id: currentActiveVisitorData?.unique_id
    }, (res) => {
      if (currentActiveVisitorData?.unique_id == res?.unique_id) {
        setMessages([]);
        setisAnimation(false);
        if (res?.updatedMessages?.length > 0) {
          res?.updatedMessages?.map((i) => {
            return setMessages((current) => [
              ...current,
              {
                message: i?.message,
                time: i?.time,
                type: i?.type,
                audio: i?.audio,
                senderID: i?.senderID,
                status: i?.status,
                uniqueMessageID: i?.uniqueMessageID,
                file: i?.file
              },
            ]);
          });
        } else {
          setMessages([]);
        }
      }
    });
    Socket.on("convoAllMessages", (data) => {
      setUpcomingMessages(data);
    });
    Socket.on("visitorFirstMessage", (data) => {
      if (currentActiveVisitorData?.unique_id == data?.senderID) {
        setOne_to_One(data);
        setisAnimation(true);
      }
    });

  }, [Socket]);

  useEffect(() => {
    if (One_to_One !== null) {
      if (currentActiveVisitorData?.unique_id == One_to_One?.senderID) {
        // let newfile = One_to_One?.file !== null ? One_to_One?.file : null
        // if (newfile !== null) {
        //     newfile.body = arrayBufferToBase64(newfile?.body)
        // };
        setMessages((prev) => [
          ...prev,
          {
            file: One_to_One.file,
            audio: One_to_One.audio,
            message: One_to_One.message,
            name: One_to_One?.senderID,
            senderID: One_to_One?.senderID,
            to: One_to_One?.to,
            uniqueMessageID: One_to_One?.uniqueMessageID,
            time: One_to_One?.time,
            type: One_to_One?.type,
          },
        ]);
        One_to_One.status = "delivered";
        Socket.emit("visitorMsgToServer", One_to_One);
      }
      setOne_to_One(null);
    }
  }, [One_to_One]);

  useEffect(() => {
    if (myIncomingMsgStatus !== null) {
      if (currentActiveVisitorData?.conversationId == myIncomingMsgStatus?.conversationId) {
        if (messages?.length > 0) {
          let findIndex = messages?.findIndex((i) => i?.uniqueMessageID == myIncomingMsgStatus?.uniqueMessageID);
          if (findIndex !== -1) {
            const updatedData = [...messages];
            updatedData.splice(findIndex, 1);
            updatedData.splice(findIndex, 0, myIncomingMsgStatus);
            setMessages(updatedData);
          }
        }
      }

      setmyIncomingMsgStatus(null);
    }
  }, [myIncomingMsgStatus]);

  useEffect(() => {
    if (UpcomingMessages !== null) {
      if (currentActiveVisitorData?.unique_id == UpcomingMessages?.unique_id) {
        setMessages([]);
        if (UpcomingMessages !== null) {
          UpcomingMessages?.updatedMessages?.map((i) => {
            return setMessages((current) => [
              ...current,
              {
                message: i?.message,
                time: i?.time,
                type: i?.type,
                audio: i?.audio,
                senderID: i?.senderID,
                status: i?.status,
                uniqueMessageID: i?.uniqueMessageID,
                file: i?.file
              },
            ]);
          });
          setUpcomingMessages(null);
        } else {
          setMessages([]);
        }
      }


    }
    else {
      setUpcomingMessages(null);
    }
  }, [UpcomingMessages]);

  const { TextArea } = Input;

  const handleUpload = (selectedFile) => {
    if (selectedFile) {
      setisAnimation(true);
      let fileInfo = {
        filename: selectedFile?.name,
        filetype: selectedFile?.type,
        fileSize: selectedFile?.size,
        body: selectedFile,
      };
      const uniqueNumber = Date.now() + Math.random() + userData?._id;
      let findId = servedData?.find((x) => x?.unique_id == currentActiveVisitorData?.unique_id);
      Socket.emit("sendMessageToVisitor", {
        conversationId: currentActiveVisitorData?.conversationId ? currentActiveVisitorData?.conversationId : findId?.convoID,
        reciver: currentActiveVisitorData?.unique_id,
        name: userData?.sudoName,
        senderID: userData?._id,
        message: currentmsg,
        file: fileInfo,
        audio: null,
        to: currentActiveVisitorData?.roomId,
        status: "wait",
        type: "file",
        uniqueMessageID: uniqueNumber
      }, (data) => {
        console.log(data, "data")
        setmyIncomingMsgStatus(data);
      });
      setMessages((prev) => [
        ...prev,
        {
          file: fileInfo,
          message: "",
          name: userData?.sudoName,
          senderID: userData?._id,
          to: currentActiveVisitorData?.roomId,
          type: "text",
          audio: null,
          uniqueMessageID: uniqueNumber,
          status: "wait",
        },
      ]);
      setcurrentmsg("");
      setaudioBlob(null);
      audioUrl(null);
      document.getElementById("file").value = "";
    }
  };
  // 'doc', 'docx', 'xls', 'xlsx', 'pdf'
  const beforeUpload = (file) => {
    const allowedExtensions = ['png', 'jpg', 'jpeg'];
    const fileExtension = file.name.split('.').pop().toLowerCase();
    const isAllowed = allowedExtensions.includes(fileExtension);
    if (!isAllowed) {
      message.error('You can only upload PNG, JPG, JPEG,and files!');
    } else {
      const maxSizeInBytes = 5 * 1024 * 1024; // 5 MB
      if (file.size > maxSizeInBytes) {
        message.error("File size exceeds 5MB.");
        setTimeout(() => {
          setcurrentmsg("");
        }, 100);
      }
      else {
        handleUpload(file);
        setTimeout(() => {
          setcurrentmsg("");
        }, 100);
      }
    }
    return false;
  };
  const props = {
    beforeUpload,
    showUploadList: false,
  };
  const sendtoClint = () => {
    if (currentmsg !== "" || audioUrl !== null) {
      setisAnimation(true);
      const uniqueNumber = Date.now() + Math.random() + userData?._id;
      let findId = servedData?.find((x) => x?.unique_id == currentActiveVisitorData?.unique_id);
      Socket.emit("sendMessageToVisitor", {
        conversationId: currentActiveVisitorData?.conversationId ? currentActiveVisitorData?.conversationId : findId?.convoID,
        name: userData?.sudoName,
        reciver: currentActiveVisitorData?.unique_id,
        senderID: userData?._id,
        message: audioUrl !== null ? "" : currentmsg,
        file: null,
        audio: audioUrl !== null ? audioBlob : null,
        to: currentActiveVisitorData?.roomId,
        type: "text",
        status: "wait",
        uniqueMessageID: uniqueNumber
      }, (data) => {
        setmyIncomingMsgStatus(data);
      });
      if (messages?.length > 0) {
        setMessages((current) => [
          ...current,
          {
            conversationId: currentActiveVisitorData?.conversationId ? currentActiveVisitorData?.conversationId : findId?.convoID,
            name: userData?.sudoName,
            to: currentActiveVisitorData?.roomId,
            message: currentmsg,
            senderID: userData?._id,
            file: null,
            audio: audioUrl !== null ? audioUrl : null,
            status: "wait",
            uniqueMessageID: uniqueNumber
          },
        ]);
      }
      else {
        setMessages([{
          conversationId: currentActiveVisitorData?.conversationId ? currentActiveVisitorData?.conversationId : findId?.convoID,
          name: userData?.sudoName,
          to: currentActiveVisitorData?.roomId,
          message: currentmsg,
          senderID: userData?._id,
          file: null,
          audio: audioUrl !== null ? audioUrl : null,
          status: "wait",
          uniqueMessageID: uniqueNumber
        }]);
      }
      audioUrl === null && setcurrentmsg("");
      setaudioBlob(null);
      setaudioUrl(null);
    }
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.keyCode === 13 && !event.shiftKey) {
        event.preventDefault();

        if (audioUrl) {
          sendtoClint();
        }
      }
    };

    if (audioUrl) {
      document.addEventListener("keydown", handleKeyPress);
    } else {
      document.removeEventListener("keydown", handleKeyPress);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [audioUrl]);

  /// onClick Join Chat
  const startChatNow = () => {
    setjoindLoading(true);
    Socket.emit("visiterServed", {
      unique_id: currentActiveVisitorData?.unique_id,
      agentID: userData?._id,
      role: userData?.role,
      displayName: userData?.sudoName,
    }, (respone) => {
      dispatch(saveVisitorServed({ unique_id: respone?.unique_id, convoID: respone?.convoId }, servedData));
      setjoindLoading(false);
    });
  };
  const sentToVisitor = (e) => {
    Socket.emit("startTyping", { roomId: currentActiveVisitorData?.roomId });
    setcurrentmsg(e.target.value);
  };
  const something = (event) => {
    if (event.keyCode === 13 && !event.shiftKey) {
      event.preventDefault();

      if (currentmsg !== "" && currentmsg !== null) {
        event.preventDefault();
        sendtoClint();
        // if (selectedImage !== null) {
        //     handleUpload();
        // }
        // else {
        //     sendtoClint();
        // }

      }
    }
    if (event.key === 'Enter' && event.shiftKey) {
      event.preventDefault(); // Prevents adding a newline by default
      setcurrentmsg(currentmsg + '\n');
    }

  };
  const onetxtchange = (i, u) => {
    if (u?.length > 0) {
      let abc = u?.map((b, index) =>
        index + 1 !== u?.length ? b?.option + "\n" : b?.option
      );
      let wow = abc.join("");
      setcurrentmsg(i + "::" + "\n" + wow?.toString());
    } else {
      setcurrentmsg(i);
    }
  };

  useEffect(() => {
    const modalContent = bottomRef.current.closest(".chatsMsgs");
    if (modalContent) {
      modalContent.scrollTo({
        top: modalContent.scrollHeight,
        behavior: "smooth",
      });
      if (isAnimation) {
        modalContent.scrollTo({
          top: modalContent.scrollHeight,
          behavior: "smooth",
        });
      } else {
        modalContent.scrollTo({
          top: modalContent.scrollHeight,
        });
      }
    }
    settypedmessage("");
  }, [messages]);

  useEffect(() => {
    if (NewEmoji !== null) {
      if (currentmsg !== "") {
        setcurrentmsg(currentmsg + NewEmoji);
        setNewEmoji(null);
      } else {
        setcurrentmsg(NewEmoji);
        setNewEmoji(null);
      }
    }
  }, [NewEmoji]);

  const onSelectEmoji = (e) => {
    setNewEmoji(e?.emoji);
    // setisEmojiOpen(false);
  };
  const renderMessage = (message) => {
    // Regex pattern to match URLs
    const urlPattern = /(https?:\/\/[^\s]+)/g;

    // Check if the message contains a URL
    if (urlPattern.test(message)) {
      // If a URL is found, replace the URL with a clickable <a> tag
      return message.split(urlPattern).map((part, i) => {
        // If the part matches the URL pattern, render it as a clickable link
        if (urlPattern.test(part)) {
          return (
            <a href={part} key={i} target="_blank" rel="noopener noreferrer">
              {part}
            </a>
          );
        }
        // If the part is not a URL, return it as plain text
        return part;
      });
    }
    // If no URL is found, just split the message by new lines and render normally
    return message
      .split("\n")
      .map((line, index) => <div key={index}>{line}</div>);
  };

  const handlePaste = (event) => {
    const clipboardData = event.clipboardData || window.clipboardData;
    const items = clipboardData.items;
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      // Check if the item is an image
      if (item.type.startsWith('image/')) {
        const file = item.getAsFile();
        beforeUpload(file);
      };
    }
  }
  useEffect(() => {
    let observer = new ResizeObserver((entries) => {
      for (let entry of entries) {
        let currentHeight = parseFloat(entry.target.style.height);
        let newHeight = currentHeight - 295;
        let updatedHeight = newHeight + "px";
        setheightofModal(updatedHeight);
      }
    });
    let elements = document.getElementsByClassName(
      currentActiveVisitorData?.unique_id
    );
    if (elements) {
      for (let i = 0; i < elements.length; i++) {
        observer.observe(elements[i]);
      }
    }
    return () => {
      observer.disconnect();
    };
  }, []);

  const addAudioElement = (blob) => {
    if (TempFile !== blob) {
      if (!recorderControls?.isRecording) {
        const url = URL.createObjectURL(blob);
        setaudioBlob({
          audio: blob,
          filename: `audio_${Date.now()}.webm`,
        });
        setaudioUrl(url);
        setTempFile(blob);
      }
    }
  };

  const onToggleBtn = () => {
    setStartRecord(true);
    setTimeout(() => {
      recorderControls.startRecording();
    }, 200);
  };

  useEffect(() => {
    if (!recorderControls?.recordingBlob) return;
    setTimeout(() => {
      setStartRecord(false);
    }, 200);
  }, [recorderControls?.recordingBlob]);

  const RemoveRecordedAudio = () => {
    setaudioBlob(null);
    setaudioUrl(null);
  };

  return (
    <div className="chatArea">
      <div style={{ height: heightofModal }} className="chatsMsgs">
        <div>
          {/* {Loading && <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: 50 }}> <Spin /></div>} */}
          {messages?.map((i, index) => {
            let findUser = overAllUsers?.find((x) => x?._id == i?.senderID);
            let updatedName = findUser ? findUser?.sudoName : i?.senderID;
            return (
              <div key={index} className="clientMsg">
                <>
                  {i?.senderID == userData?._id ? (
                    <>
                      {i?.type === "joiner" ? (
                        <div
                          className="joins"
                          style={{
                            width: "100%",
                            fontStyle: "italic",
                            textAlign: "center",
                            lineHeight: "0px",
                            marginBottom: "30px",
                            fontSize: "12px",
                          }}
                        >
                          <p
                            className="agentjointext"
                            style={{ textAlign: "center" }}
                          >
                            <span className="leaveTitle">{i?.message}</span>
                          </p>
                          <span
                            style={{
                              fontSize: 10,
                              backgroundColor: "white",
                              padding: "0px 12px",
                              zIndex: "1",
                              position: "relative",
                              borderRadius: "19px",
                            }}
                          >
                            {moment(i?.time).fromNow(true)}
                          </span>
                        </div>
                      ) : i?.type === "leave" ? (
                        <div
                          className="leave"
                          style={{
                            width: "100%",
                            fontStyle: "italic",
                            textAlign: "center",
                            lineHeight: "0px",
                            marginBottom: "30px",
                            fontSize: "12px",
                          }}
                        >
                          <p
                            className="agentjointext"
                            style={{ textAlign: "center" }}
                          >
                            <span className="leaveTitle">{i?.message}</span>
                          </p>
                          <span
                            style={{
                              fontSize: 10,
                              backgroundColor: "white",
                              padding: "0px 12px",
                              zIndex: "1",
                              position: "relative",
                              borderRadius: "19px",
                            }}
                          >
                            {moment(i?.time).fromNow(true)}
                          </span>
                        </div>
                      ) : (
                        <div className="conversation-list right">
                          <div className="chat-avatar right">
                            <Avatar icon={<UserOutlined />} />
                          </div>
                          <div className="user-chat-content">
                            <div className="ctext-wrap">
                              <div className="ctext-wrap-content right">
                                {/* {i?.message} */}
                                <p className="mb-0">
                                  {" "}
                                  {renderMessage(i?.message)}
                                </p>
                                {i?.file !== null && (
                                  <>
                                    <div className="download">
                                      <div className="avatar-sm me-3 ms-0">
                                        <div className="ava-icon">
                                          <FileTextOutlined />
                                        </div>
                                      </div>
                                      <div className="flex-grow-1">
                                        <div className="text-start">
                                          <h5 className="font-size-14 mb-1">
                                            {i?.file?.filename?.substring(0, 8)}
                                            ...
                                            {i?.file?.filename?.substring(20)}
                                          </h5>
                                          <p className="text-muted font-size-13 mb-0"></p>
                                        </div>
                                      </div>
                                      <div className="avatar-sm me-3 ms-0">
                                        <div className="avatar-down">
                                          {/* {i?.file.blob && <Downloader blob={i?.file.blob} filename={i?.file.filename} />} */}
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                                {/* {i?.audio && i?.audio !== null && <audio style={{ height: 35, width: 250 }} src={i?.audio?.url} controls />} */}

                                {i?.audio && i?.audio !== null && (
                                  <AudioPlayerCustom audioUrl={i?.audio?.url} />
                                )}

                                <p className="chat-time mb-0">
                                  {/* <i className="ri-time-line align-middle"></i> */}
                                  <span style={{ marginRight: 10 }}>
                                    {i?.status == "wait" ? (
                                      <img
                                        src={clockImg}
                                        style={{ width: 15, height: 13 }}
                                        alt="React Logo"
                                      />
                                    ) : i?.status == "sent" ? (
                                      <img
                                        src={singleCheck}
                                        style={{ width: 15, height: 13 }}
                                        alt="React Logo"
                                      />
                                    ) : i?.status == "delivered" ? (
                                      <img
                                        src={delevredIcon}
                                        style={{ width: 15, height: 13 }}
                                        alt="React Logo"
                                      />
                                    ) : (
                                      <img
                                        src={seenIcon}
                                        style={{ width: 15, height: 13 }}
                                        alt="React Logo"
                                      />
                                    )}
                                  </span>
                                  <span className="align-middle">
                                    {i?.time !== undefined && i?.time !== null
                                      ? moment(i?.time).fromNow()
                                      : moment(
                                        new Date().toISOString()
                                      ).fromNow()}
                                    {/* {moment(i?.time).fromNow()} */}
                                  </span>
                                </p>
                              </div>
                            </div>
                            <div className="conversation-name">
                              {userData?.sudoName}
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      {i?.type === "joiner" ? (
                        <div
                          className="joins"
                          style={{
                            width: "100%",
                            fontStyle: "italic",
                            textAlign: "center",
                            lineHeight: "0px",
                            marginBottom: "30px",
                            fontSize: "12px",
                          }}
                        >
                          <p
                            className="agentjointext"
                            style={{ textAlign: "center" }}
                          >
                            <span className="leaveTitle">{i?.message}</span>
                          </p>
                          <span
                            style={{
                              fontSize: 10,
                              backgroundColor: "white",
                              padding: "0px 12px",
                              zIndex: "1",
                              position: "relative",
                              borderRadius: "19px",
                            }}
                          >
                            {moment(i?.time).fromNow(true)}
                          </span>
                        </div>
                      ) : i?.type === "disconnect" ? (
                        <div
                          className="disconnect"
                          style={{
                            width: "100%",
                            fontStyle: "italic",
                            textAlign: "center",
                            lineHeight: "0px",
                            marginBottom: "30px",
                            fontSize: "12px",
                          }}
                        >
                          <p
                            className="agentjointext"
                            style={{ textAlign: "center" }}
                          >
                            <span className="leaveTitle">{i?.message}</span>
                          </p>
                          <span
                            style={{
                              fontSize: 10,
                              backgroundColor: "white",
                              padding: "0px 12px",
                              zIndex: "1",
                              position: "relative",
                              borderRadius: "19px",
                            }}
                          >
                            {moment(i?.time).fromNow(true)}
                          </span>
                        </div>
                      ) : i?.type === "leave" ? (
                        <div
                          className="leave"
                          style={{
                            width: "100%",
                            fontStyle: "italic",
                            textAlign: "center",
                            lineHeight: "0px",
                            marginBottom: "30px",
                            fontSize: "12px",
                          }}
                        >
                          <p
                            className="agentjointext"
                            style={{ textAlign: "center" }}
                          >
                            <span className="leaveTitle">
                              {renderMessage(i?.message)}
                            </span>
                          </p>
                          <span
                            style={{
                              fontSize: 10,
                              backgroundColor: "white",
                              padding: "0px 12px",
                              zIndex: "1",
                              position: "relative",
                              borderRadius: "19px",
                            }}
                          >
                            {moment(i?.time).fromNow(true)}
                          </span>
                        </div>
                      ) : i?.type === "widget" ? (
                        <div
                          className="wid"
                          style={{
                            width: "100%",
                            fontStyle: "italic",
                            textAlign: "center",
                            lineHeight: "0px",
                            marginBottom: "30px",
                            fontSize: "12px",
                          }}
                        >
                          <p
                            className="agentjointext"
                            style={{ textAlign: "center", fontSize: 12 }}
                          >
                            <span className="leaveTitle">
                              {renderMessage(i?.message)}
                            </span>
                          </p>
                          <span
                            style={{
                              fontSize: 10,
                              backgroundColor: "white",
                              padding: "0px 12px",
                              zIndex: "1",
                              position: "relative",
                              borderRadius: "19px",
                            }}
                          >
                            {moment(i?.time).fromNow(true)}
                          </span>
                        </div>
                      ) : (
                        <>
                          <div className="conversation-list">
                            <div className="chat-avatar">
                              <Avatar icon={<UserOutlined />} />
                            </div>
                            <div className="user-chat-content">
                              <div className="ctext-wrap">
                                <div className="ctext-wrap-content">
                                  <p className="mb-0">
                                    {renderMessage(i?.message)}
                                  </p>
                                  {i?.file !== null && (
                                    <>
                                      <div className="download">
                                        <div className="avatar-sm me-3 ms-0">
                                          <div className="ava-icon">
                                            <FileTextOutlined />
                                          </div>
                                        </div>
                                        <div className="flex-grow-1">
                                          <div className="text-start">
                                            <h5 className="font-size-14 mb-1">
                                              {i?.file?.filename?.substring(
                                                0,
                                                8
                                              )}
                                              ...
                                              {i?.file?.filename?.substring(20)}
                                            </h5>
                                            <p className="text-muted font-size-13 mb-0"></p>
                                          </div>
                                        </div>
                                        <div className="avatar-sm me-3 ms-0">
                                          <div className="avatar-down">
                                            <Downloader
                                              file={i?.file}
                                              filename={i?.file.filename}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}

                                  {/* {i?.audio && i?.audio !== null && <audio style={{ height: 35, width: 250 }} src={i?.audio?.url} controls />} */}

                                  {i?.audio && i?.audio !== null && (
                                    <AudioPlayerCustom
                                      audioUrl={i?.audio?.url}
                                    />
                                  )}

                                  <p className="chat-time mb-0">
                                    <i className="ri-time-line align-middle"></i>
                                    <span className="align-middle">
                                      {moment(i?.time).fromNow()}
                                    </span>
                                  </p>
                                </div>
                              </div>
                              <div className="conversation-name">
                                #[{updatedName}]
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </>
              </div>
            );
          })}
          {Typing && (
            <div
              className="conversation-list"
              style={{ display: "flex", alignItems: "center" }}
            >
              <div
                className="chat-avatar"
                style={{
                  color: "dimgrey",
                  fontStyle: "italic",
                }}
              >
                {/* <Avatar icon={<UserOutlined />} /> */}
                visitor is typing
              </div>
              <div className="user-chat-content">
                <div style={{ marginBottom: 4, marginLeft: 4 }}>
                  <span
                    style={{ textAlign: "center", padding: "0px 5px" }}
                    color="#000"
                  >
                    <Typingdots />
                  </span>
                </div>
                {/* <div className="conversation-name">#[{updatedName}]</div> */}
              </div>
            </div>
          )}
          <div ref={bottomRef} />
        </div>
      </div>

      <div>
        {servedData?.some(
          (it) => it?.unique_id == currentActiveVisitorData?.unique_id
        ) ||
          (currentActiveVisitorData?.servedBy?.length > 0 &&
            currentActiveVisitorData?.servedBy?.find(
              (item) => item.id === userData?._id
            )) ? (
          <div className="bottom_text_wrapper">
            <div
              style={{
                marginTop: -5,
                marginBottom: 1,
                fontStyle: "italic",
                color: "dimgrey",
                paddingBottom: "6px",
              }}
            >
              {/* Visitor : */}
              <input
                type="text"
                value={typedmessage}
                readOnly
                style={{
                  border: "none",
                  outline: "none",
                  background: "transparent",
                  color: "dimgrey",
                  fontStyle: "italic",
                  paddingBottom: "6px",
                  textAlign: "left",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  direction: "rtl",
                  width: "100%",
                }}
              />
            </div>
            {startRecord && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: "0px 20px",
                  position: "absolute",
                  top: "85px",
                  width: "100%",
                  left: 0,
                  zIndex: 9999,
                }}
              >
                <AudioRecorder
                  onRecordingComplete={(e) => addAudioElement(e)}
                  recorderControls={recorderControls}
                  // audioTrackConstraints={{
                  //     noiseSuppression: true,
                  //     echoCancellation: true,
                  //     sampleSize: 10,
                  //     sampleRate: 32
                  // }}
                  // downloadOnSavePress={true}
                  downloadFileExtension={"mp3"}
                  showVisualizer={true}
                  classes={{ AudioRecorderStartSaveClass: "save_audio_recorder" }}
                />
              </div>
            )}
            <div style={{ position: "relative" }}>
              {audioUrl ? (
                <></>
              ) : (
                <TextArea
                  style={{
                    height: 110,
                    resize: "none",
                  }}
                  onPaste={handlePaste}
                  onKeyDown={(e) => something(e)}
                  value={currentmsg}
                  onChange={(e) => sentToVisitor(e)}
                  placeholder="Type"
                />
              )}

              {audioUrl && (
                <div className="send_audio_wrapper">
                  <AudioPlayerCustom audioUrl={audioUrl} />
                  <FaTrashAlt
                    className="trash_audio"
                    onClick={() => RemoveRecordedAudio()}
                  />
                </div>
              )}

              {currentmsg && (
                <div
                  style={{
                    position: "absolute",
                    bottom: 0,
                    left: 1,
                    borderBottomLeftRadius: 5,
                    width: "100%",
                    overflowY: "scroll",
                    height: 40,
                  }}
                >
                  {findingTxt?.map((i, index) => (
                    <div
                      key={index}
                      onClick={() => onetxtchange(i?.message, i?.options)}
                      style={{
                        backgroundColor: "#D3D3D3",
                        fontSize: 14,
                        cursor: "pointer",
                        borderWidth: 1,
                        borderColor: "red",
                      }}
                    >
                      {i?.shortcut} : {i?.message}
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className="footerChat">
              <input
                id="file"
                type="file"
                accept=".png, .jpg, .jpeg, .doc, .docx, .xls, .xlsx, .pdf"
                onChange={handleFileChange}
                style={{ display: "none" }}
                ref={fileInputRef}
              />
              {audioUrl ? (
                <></>
              ) : (
                <>
                  <Button
                    style={{ marginRight: 5 }}
                    type="primary"
                    onClick={() => setisEmojiOpen(!isEmojiOpen)}
                    icon={isEmojiOpen ? <DownOutlined /> : <SmileOutlined />}
                  />

                  <div style={{ position: "absolute", bottom: 60 }}>
                    <EmojiPicker
                      onEmojiClick={onSelectEmoji}
                      open={isEmojiOpen}
                      emojiStyle={"google"}
                    />
                  </div>
                  <Button
                    onClick={() => onToggleBtn()}
                    style={{ marginRight: 5 }}
                    type="primary"
                    icon={<AudioOutlined />}
                  >
                    {/* <Recoder /> */}
                  </Button>

                  <Button
                    style={{ marginRight: 5 }}
                    onClick={() => fileInputRef.current.click()}
                    type="primary"
                    icon={<UploadOutlined />}
                  >
                    Select File
                  </Button>
                </>
              )}
              <Button
                style={{ marginRight: 2 }}
                onClick={() => sendtoClint()}
                type="primary"
              >
                Send
              </Button>
            </div>
          </div>
        ) : (
          <div
            style={{
              width: "100%",
              textAlign: "center",
              backgroundColor: "#D3D3D3",
              padding: "60px 0px 60px 0px",
            }}
          >
            <Button disabled={joindLoading} onClick={() => startChatNow()}>
              Join Chat
            </Button>
            {/* {isLoadClient ? <p>Please Wait While We Connect you to Visitor...</p> : <p>You're viewing this chat. Click to join the chat.</p>} */}
          </div>
        )}
      </div>
    </div>
  );
};

export default DrageCurrentChat;
