import { Checkbox, Tooltip } from "antd";
import React from "react";
import { TiStarOutline } from "react-icons/ti";
import { LuMessagesSquare } from "react-icons/lu";
import UseRandomColorStyle from "../userandomcolorstyle/UseRandomColorStyle";

const EmailInboxMessages = ({
  name,
  message,
  time,
  isRead,
  onViewReplyClick,
}) => {
  const { style } = UseRandomColorStyle();

  const getInitials = (name = "") => {
    const words = name.split(" ");

    if (words.length >= 3) {
      return `${words[0].charAt(0).toUpperCase()}${words[words.length - 1]
        .charAt(0)
        .toUpperCase()}`;
    } else {
      return words.map((word) => word.charAt(0).toUpperCase()).join("");
    }
  };

  return (
    <div
      className={`email-messages-main-wrapper ${
        isRead === false && "not-readed"
      }`}
      onClick={() => onViewReplyClick()}
    >
      <div className="left">
        <Checkbox />
        <Tooltip title="Add to favorites" placement={"top"}>
          <TiStarOutline />
        </Tooltip>
        <Tooltip title="Mark as important" placement={"top"}>
          <LuMessagesSquare />
        </Tooltip>
      </div>
      <div className="details">
        <div className="first-alpha" style={{ ...style }}>
          {getInitials(name)}
        </div>
        <p>{name}</p>
      </div>
      <div className="message">
        <p>{message}</p>
      </div>
      <div className="right">
        <span>{time}</span>
      </div>
    </div>
  );
};

export default EmailInboxMessages;
